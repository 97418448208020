import {
  GET_LANG
} from "../../actions/API/langue.action";

const initialState = {};

export default function langReducer(state = initialState, action) {
  switch (action.type) {
  case GET_LANG:
    return action.payload;
  default:
    return state;
  }
}
