import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { Alert, Box, Button, FormControl, InputLabel, Menu, MenuItem, Modal, Snackbar, Tooltip } from "@mui/material";
import { GroupResponse } from "../../models/entities/groupe/response/group.response";
import { useForm } from "react-hook-form";
import GroupService from "../../services/api/group.service";
import { UserModel } from "../../models/entities/user/user.response.model";
import { RoleGroupMembershipEnum } from "../../models/enum/role-group-membership.enum";
import CardService from "../../services/api/card.service";
import { CardModel } from "../../models/entities/card/response/card.response";
import { JoinedConversationResponse } from "../../models/entities/conversation/response/joined-conversation.response";
import MemberComponent from "./MemberComponent";
import SettingsIcon from "@mui/icons-material/Settings";

interface GroupModalProps {
  group: GroupResponse;
  token: string;
  actualUser: UserModel;
  joinedProfiles: JoinedConversationResponse[];
  setCall: void;
  call: boolean;
}
const GroupModal: React.FC<GroupModalProps> = ({ group, token, actualUser, joinedProfiles, setCall, call }) => {
  const { register, handleSubmit, getValues, resetField } = useForm<GroupResponse>({ mode: "onChange" });

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [openFailed, setOpenFailed] = useState(false);
  const [openCreated, setOpenCreated] = useState(false);
  const [titleModal, setTitleModal] = useState("Members");

  const [cards, setCards] = useState<CardModel[]>([]);
  const [selectedCardIds, setSelectedCardIds] = useState([]);
  const [message, setMessage] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    CardService.getAllMyConnectedCards(token)
      .then((response) => {
        if (response.status === 200) {
          setCards(response.data);
        }
      })
      .catch(() => {
        setMessage("Error when fetching cards");
        setTimeout(() => {
          setOpenCreated(false);
        }, 1000);
      });
  }, [token]);

  const handleCardSelectChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSelectedCardIds(event.target.value as never[]);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const handleOpenShowMembers = (): void => {
    resetField("name");
    setTitleModal("Members");
    handleOpen();
  };

  const handleRemoveFromGroup = (memberId: string): void => {
    GroupService.removeCardFromGroupAdmin({ groupId: group.id, cardId: memberId }, token)
      .then((r) => {
        if (r.status === 200) {
          handleClose();
          setOpenCreated(true);
          setMessage("Membre supprimé");
          handleCloseMenu();
          setTimeout(() => {
            setOpenCreated(false);
          }, 4000);
        } else {
          setOpenFailed(true);
          setMessage("Erreur lors de la suppression");
          setTimeout(() => {
            setOpenFailed(false);
          }, 4000);
        }
      })
      .catch(() => {
        setOpenFailed(true);
        setMessage("Erreur lors de la suppression");
        setTimeout(() => {
          setOpenFailed(false);
        }, 4000);
      });
  };

  // This function should make the API call to give admin rights to a member.
  const handleGiveAdminRights = (memberId: string): void => {
    GroupService.giveAdminRightsPublic({ groupId: group.id, cardId: memberId }, token)
      .then((r) => {
        if (r.status === 200) {
          handleClose();
          setOpenCreated(true);
          setMessage("Member have now admin rights");
          handleCloseMenu();
          setTimeout(() => {
            setOpenCreated(false);
          }, 4000);
        } else {
          setOpenFailed(true);
          setMessage("Error when giving admin rights");
          setTimeout(() => {
            setOpenFailed(false);
          }, 4000);
        }
      })
      .catch(() => {
        setOpenFailed(true);
        setMessage("Error when giving admin rights");
        setTimeout(() => {
          setOpenFailed(false);
        }, 4000);
      });
  };

  // This function should make the API call to remove admin rights from a member.
  const handleRemoveAdminRights = (memberId: string): void => {
    GroupService.removeAdminRightsPublic({ groupId: group.id, cardId: memberId }, token)
      .then((r) => {
        if (r.status === 204) {
          handleClose();
          setMessage("Admin rights removed");
          handleCloseMenu();
          setTimeout(() => {
            setOpenCreated(false);
          }, 4000);
        } else {
          setOpenFailed(true);
          setMessage("Error when removing admin rights");
          setTimeout(() => {
            setOpenFailed(false);
          }, 4000);
        }
      })
      .catch(() => {
        setOpenFailed(true);
        setMessage("Error when removing admin rights");
        setTimeout(() => {
          setOpenFailed(false);
        }, 4000);
      });
  };

  const handleDeleteGroup = (groupId: string): void => {
    GroupService.deleteGroupPublic(groupId, token)
      .then((r) => {
        if (r.status === 204) {
          handleClose();
          setOpenCreated(true);
          setMessage("Group deleted");
          handleCloseMenu();
          setTimeout(() => {
            setOpenCreated(false);
          }, 4000);
        } else {
          setOpenFailed(true);
          setMessage("Error when deleting group");
          setTimeout(() => {
            setOpenFailed(false);
          }, 4000);
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("Error when deleting group");
        setTimeout(() => {
          setOpenFailed(false);
        }, 4000);
      });
  };

  const handleLeaveGroup = (memberId: string): void => {
    GroupService.leaveGroupPublic({ cardId: memberId, groupId: group.id }, token)
      .then((r) => {
        if (r.status === 204) {
          handleClose();
          setOpenFailed(true);
          setMessage("Group left");
          setTimeout(() => {
            setOpenCreated(false);
          }, 4000);
        } else {
          setOpenFailed(true);
          setMessage("Error when leaving group");
          setTimeout(() => {
            setOpenFailed(false);
          }, 4000);
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("Error when leaving group");
        setTimeout(() => {
          setOpenFailed(false);
        }, 4000);
      });
  };

  const handleCloseSnackbar = (event: any, reason: any): void => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };
  return (
    <>
      <SettingsIcon
        onClick={() => handleOpenShowMembers()}
        style={{ color: "#fff", marginRight: "20px", cursor: "pointer" }}
      />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="container-modal">
            <div className="header-modal">
              <div className="title-header">{group.name}</div>
              <img
                className="button-close"
                onClick={handleClose}
                src="/assets/logo/close_simple.svg"
                alt="close button"
              />
            </div>
            <div>
              {group.members.map((member, index) => {
                const isAdmin = member.role === RoleGroupMembershipEnum.ADMIN;
                const isMember = member.role === RoleGroupMembershipEnum.MEMBER;
                const isOwner = member.role === RoleGroupMembershipEnum.OWNER;
                const isCurrentUser = member?.card?.owner?.user?.id === actualUser.id;
                const isConnected = joinedProfiles.some(
                  (joinedConversation) => member.card?.owner?.id === joinedConversation.profile.id,
                );
                // Get the role of the actual user
                const actualUserRole = group.members.find(
                  (member) => member.card?.owner?.user?.id === actualUser.id,
                )?.role;
                const isActualUserAdmin = actualUserRole === RoleGroupMembershipEnum.ADMIN;
                const isActualUserMember = actualUserRole === RoleGroupMembershipEnum.MEMBER;
                const isActualUserOwner = actualUserRole === RoleGroupMembershipEnum.OWNER;

                return (
                  <MemberComponent
                    key={member.id}
                    index={index}
                    group={group}
                    member={member}
                    actualUser={actualUser}
                    isAdmin={isAdmin}
                    isMember={isMember}
                    isOwner={isOwner}
                    isCurrentUser={isCurrentUser}
                    isConnected={isConnected}
                    isActualUserAdmin={isActualUserAdmin}
                    isActualUserMember={isActualUserMember}
                    isActualUserOwner={isActualUserOwner}
                    handleRemoveFromGroup={handleRemoveFromGroup}
                    handleGiveAdminRights={handleGiveAdminRights}
                    handleRemoveAdminRights={handleRemoveAdminRights}
                    handleDeleteGroup={handleDeleteGroup}
                    handleLeaveGroup={handleLeaveGroup}
                  />
                );
              })}
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar open={openCreated} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GroupModal;
