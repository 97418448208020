import http from "../http-common";
import { GetCompanyDiscoveryRequest } from "../../models/entities/compagny/request/get-company-discovery.request";
import { GetCompanyWithCriteriaRequest } from "../../models/entities/compagny/request/get-company-with-criteria.request";
import { CreateUserForCompanyRequest } from "../../models/entities/compagny/request/create-user-for-company.request";
import { AddCompanyEmployeeRequest } from "../../models/entities/compagny/request/add-company-employee.request";
import { CreateCompanyRequest } from "../../models/entities/compagny/request/create-company.request";
import { UpdateCompanyRequest } from "../../models/entities/compagny/request/update-company.request";
import { TransferOwnershipOfCompanyRequest } from "../../models/entities/compagny/request/transfer-ownership-of-company.request";
import { CreateCardPresetRequest } from "../../models/entities/compagny/request/create-card-preset.request";
import { RemoveCompanyEmployeeRequest } from "../../models/entities/compagny/request/remove-company-employee.request";

const getAllCompanies = (token: string) => {
  return http.get<any>("/company/admin/get-all-companies/false/-1/-1/", {
    headers: { Authorization: "Bearer " + token },
  });
};
const getCompanyById = (id: string, token: string) => {
  return http.get<any>(`/company/public/get-company-by-id//${id}`, { headers: { Authorization: "Bearer " + token } });
};

const getCompanyByUserId = (userId: string, token: string) => {
  return http.get<any>(`/company/public/get-company-by-user-id/${userId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};
const getCompanyByOwner = (token: string) => {
  return http.get<any>("/company/company/get-my-company-by-owner-user-id", {
    headers: { Authorization: "Bearer " + token },
  });
};
const getEmployeebyCompanyByOwner = (token: string) => {
  return http.get<any>("/company/company/get-my-company-employee-by-owner-user-id", {
    headers: { Authorization: "Bearer " + token },
  });
};
const getCompanyByProfileId = (profileId: string, token: string) => {
  return http.get<any>(`/company/public/get-company-by-profile-id/${profileId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getCompanyDiscovery = (data: GetCompanyDiscoveryRequest, token: string) => {
  return http.post<any>("/company/public/get-company-discovery", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getCompanyWithCriteria = (data: GetCompanyWithCriteriaRequest, token: string) => {
  return http.post<any>("/company/admin/get-company-with-criteria", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const createUserForCompany = (data: CreateUserForCompanyRequest, token: string) => {
  return http.post<any>("/company/company/create-user-and-profile-for-company", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const addCompanyEmployee = (data: AddCompanyEmployeeRequest, token: string) => {
  return http.post<any>("/company/public/add-company-employee", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const removeCompanyEmployee = (data: RemoveCompanyEmployeeRequest, token: string) => {
  return http.post<any>("/company/public/remove-company-employee", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const giveRightsToCompanyEmployee = (data: AddCompanyEmployeeRequest, token: string) => {
  return http.post<any>("/company/public/give-rights-to-company-employee", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const adminAddCompanyEmployee = (data: AddCompanyEmployeeRequest, token: string) => {
  return http.post<any>("/company/admin/add-company-employee", data, { headers: { Authorization: "Bearer " + token } });
};

const adminRemoveCompanyEmployee = (data: AddCompanyEmployeeRequest, token: string) => {
  return http.post<any>("/company/admin/remove-company-employee", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const adminGiveRightsToCompanyEmployee = (data: AddCompanyEmployeeRequest, token: string) => {
  return http.post<any>("/company/admin/give-rights-to-company-employee", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const createCompany = (data: CreateCompanyRequest, token: string) => {
  return http.post<any>("/company/company/create-company", data, { headers: { Authorization: "Bearer " + token } });
};

const updateCompany = (id: string, data: UpdateCompanyRequest, token: string) => {
  return http.put<any>(`/company/public/update-company/${id}`, data, { headers: { Authorization: "Bearer " + token } });
};

const adminUpdateCompany = (id: string, data: UpdateCompanyRequest, token: string) => {
  return http.put<any>(`/company/admin/update-company/${id}`, data, { headers: { Authorization: "Bearer " + token } });
};

const adminCreateCompany = (data: CreateCompanyRequest, token: string) => {
  return http.post<any>("/company/admin/create-company", data, { headers: { Authorization: "Bearer " + token } });
};

const transferOwnershipOfCompany = (id: string, data: TransferOwnershipOfCompanyRequest, token: string) => {
  return http.put<any>("/company/public/transfer-ownership-of-company", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const adminTransferOwnershipOfCompany = (id: string, data: UpdateCompanyRequest, token: string) => {
  return http.put<any>("/company/admin/transfer-ownership-of-company", data, {
    headers: { Authorization: "Bearer " + token },
  });
};
const deleteCompany = (id: string, token: string) => {
  return http.delete<any>(`/company/public/delete-company/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const adminDeleteCompany = (id: string, token: string) => {
  return http.delete<any>(`/company/admin/hard-delete-company/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const adminRestoreCompany = (id: string, data: UpdateCompanyRequest, token: string) => {
  return http.put<any>(`/company/admin/restore-company/${id}`, data, { headers: { Authorization: "Bearer " + token } });
};
const createCardPreset = (data: CreateCardPresetRequest, token: string) => {
  return http.post<any>("/company/public/create-card-preset", data, { headers: { Authorization: "Bearer " + token } });
};

const CompanyService = {
  getAllCompanies,
  getCompanyById,
  getCompanyByUserId,
  getCompanyByProfileId,
  getCompanyDiscovery,
  getCompanyWithCriteria,
  createUserForCompany,
  addCompanyEmployee,
  removeCompanyEmployee,
  giveRightsToCompanyEmployee,
  adminAddCompanyEmployee,
  adminRemoveCompanyEmployee,
  adminGiveRightsToCompanyEmployee,
  getEmployeebyCompanyByOwner,
  createCompany,
  updateCompany,
  adminUpdateCompany,
  adminCreateCompany,
  transferOwnershipOfCompany,
  adminTransferOwnershipOfCompany,
  deleteCompany,
  adminDeleteCompany,
  adminRestoreCompany,
  getCompanyByOwner,
  createCardPreset,
};

export default CompanyService;
