import React from "react";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {useSelector} from "react-redux";



const SecondStep = (alignment,setAlignment) => {
  const lang  = useSelector((state) => state.langReducer);

  const handleAlignment = (
    event,
    newAlignment,
  ) => {
    setAlignment(newAlignment);
  };

  return (
    <div className="component--second-step">
      <div className="title-step">{ lang === "FR" ? "Choix de emplacement des informations" : "Choice of information location"}</div>
      <div className="description">{ lang === "FR" ? "Cette sections concerne la personnalisation du placement sur la carte des informations souhaiter" : "This section allows you to customize the placement of information on the map."}</div>
      <ToggleButtonGroup
        value={alignment}
        exclusive
        sx={{marginBottom:10}}
        onChange={handleAlignment}
        aria-label="text alignment"
      >
        <ToggleButton value="left" aria-label="left aligned">
          <FormatAlignLeftIcon />
        </ToggleButton>
        <ToggleButton value="center" aria-label="centered">
          <FormatAlignCenterIcon />
        </ToggleButton>
        <ToggleButton value="right" aria-label="right aligned">
          <FormatAlignRightIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};


export default SecondStep;
