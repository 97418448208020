import React, { useEffect, useRef, useState } from "react";
import VideoChatIcon from "@mui/icons-material/VideoChat";
import SendIcon from "@mui/icons-material/Send";
import { Alert, Avatar, Box, Button, MenuItem, Modal, Snackbar, TextField, Tooltip } from "@mui/material";
import MuiUserUtils from "../../utils/mui.user.utils";
import { MessageLeft, MessageRight, MesssageUser } from "../../components/message/Message";
import io, { Socket } from "socket.io-client";
import { MessageResponse } from "../../models/entities/conversation/response/message.response";
import { ConversationResponse } from "../../models/entities/conversation/response/conversation.response";
import AuthService from "../../services/api/auth.service";
import { useSelector } from "react-redux";
import { SentMessageRequest } from "../../models/entities/conversation/request/sent-message.request";
import { CardModel } from "../../models/entities/card/response/card.response";
import CardService from "../../services/api/card.service";
import { TypeOfCardEnum } from "../../models/enum/type-of-card.enum";
import GroupModal from "./GroupModal";
import GroupService from "../../services/api/group.service";
import { JoinedConversationResponse } from "../../models/entities/conversation/response/joined-conversation.response";
import AddIcon from "@mui/icons-material/Add";
import { ConnectedCardResponse } from "../../models/entities/card/response/connected-card.response";
import { GroupResponse } from "../../models/entities/groupe/response/group.response";
import isEmpty from "../../utils/isempty.utils";

let socket: Socket;

const Chat = () => {
  const user = useSelector((state: any) => state.userReducer);
  const [token, setToken] = useState<any>("");
  const [conversations, setConversations] = useState<ConversationResponse[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedConversation, setSelectedConversation] = useState<ConversationResponse>({
    avatarMedia: "",
    connectedCard: undefined,
    content: "",
    createdAt: "",
    deletedAt: "",
    group: undefined,
    id: "",
    joinedProfiles: [],
    messages: [],
    updatedAt: "",
  });
  const [userCardId, setUserCardId] = useState<string>();
  const [messageContent, setMessageContent] = useState("");
  const bottomRef = useRef<null | HTMLDivElement>(null);
  // snackBar
  const [message, setMessage] = useState("");
  const [openFailed, setOpenFailed] = useState(false);
  const [openCreated, setOpenCreated] = useState(false);

  // modal create group
  const [cards, setCards] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [selectedCardId, setSelectedCardId] = useState("");
  // modal add card to group
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [selectedCardIds2, setSelectedCardIds2] = useState<string[]>([]);
  const [connectedCard, setConnectedCards] = useState<CardModel[]>([]);
  const [refreshCall, setRefrehCall] = useState<any>(false);

  const handleSecondModalOpen = () => {
    setIsSecondModalOpen(true);
  };

  const handleSecondModalClose = () => {
    setIsSecondModalOpen(false);
  };
  const handleCardSelectionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCardIds2(event.target.value as string[]);
  };

  const availableCards = connectedCard.filter((card: CardModel) => {
    if (selectedConversation?.group) {
      return !selectedConversation.group.members.some((member) => member?.card?.id === card.id);
    }
    return true;
  });

  const handleCloseSnackbar = (event: any, reason: any): void => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };

  const handleConversationClick = (conversation: ConversationResponse) => {
    setSelectedConversation(conversation);
    let userCardId: string | undefined;

    // Check if 'connectedCard' is defined
    if (conversation.connectedCard) {
      if (conversation.connectedCard.cardEntityOne.owner.user?.id === user.id) {
        userCardId = conversation.connectedCard.cardEntityOne.id;
      } else if (conversation.connectedCard.cardEntityTwo.owner.user?.id === user.id) {
        userCardId = conversation.connectedCard.cardEntityTwo.id;
      }
    }
    // Else, check if 'group' is defined
    else if (conversation.group) {
      const userMembership = conversation.group.members.find(
        (membership) => membership?.card?.owner?.user?.id === user.id,
      );

      if (userMembership) {
        userCardId = userMembership.card?.id;
      }
    }
    if (userCardId) {
      setUserCardId(userCardId);
    }
  };

  const initSocketConnection = () => {
    socket = io("wss://api.sharemee.weworkstudio.fr/", {
      protocols: ["websocket"],
      extraHeaders: {
        authorization: "Bearer " + token,
      },
    });

    socket.on("connect", () => {
      setIsLoading(true);
    });

    socket.on(
      "joined_profile",
      ({
        joinedConversation,
        conversationId,
      }: {
        joinedConversation: JoinedConversationResponse;
        conversationId: string;
      }) => {
        // Mettre à jour la liste des conversations
        setConversations((prevConversations) => {
          // Faire une copie de l'état précédent
          const newConversations = [...prevConversations];

          newConversations.forEach((conversation) => {
            if (conversation.id === conversationId) {
              // Trouver le profile dans la liste des profiles qui ont rejoint
              const profileIndex = conversation.joinedProfiles.findIndex((p) => p.id === joinedConversation.profile.id);

              if (profileIndex >= 0) {
                // Si le profile est déjà dans la liste, mettre à jour
                conversation.joinedProfiles[profileIndex] = joinedConversation;
              } else {
                // Sinon, ajouter le nouveau profile à la liste
                conversation.joinedProfiles.push(joinedConversation);
              }
            }
          });

          return newConversations;
        });

        // Mettre à jour la conversation sélectionnée
        if (selectedConversation && selectedConversation.id === conversationId) {
          setSelectedConversation((prevConversation) => {
            if (prevConversation) {
              // Faire une copie de l'état précédent
              const newSelectedConversation = { ...prevConversation };

              const profileIndex = newSelectedConversation.joinedProfiles.findIndex(
                (p) => p.id === joinedConversation.profile.id,
              );

              if (profileIndex >= 0) {
                // Si le profile est déjà dans la liste, mettre à jour
                newSelectedConversation.joinedProfiles[profileIndex] = joinedConversation;
              } else {
                // Sinon, ajouter le nouveau profile à la liste
                newSelectedConversation.joinedProfiles.push(joinedConversation);
              }

              return newSelectedConversation;
            }

            return prevConversation;
          });
        }
      },
    );

    socket.on("receive_message", (message: MessageResponse) => {
      setConversations((prevConversations) => {
        let updatedConversation: ConversationResponse | null = selectedConversation;
        const newConversations = prevConversations.map((conversation) => {
          if (conversation.id === message.conversationId) {
            // Check if the message ID is already in the conversation
            const messageExists = conversation?.messages?.some((msg) => msg.id === message.id);
            if (!messageExists) {
              updatedConversation = {
                ...conversation,
                messages: [message, ...(conversation?.messages ?? [])],
              };
              return updatedConversation;
            }
          }
          return conversation;
        });

        setSelectedConversation((selectedConversation) => {
          if (selectedConversation?.id === message.conversationId) {
            return updatedConversation ?? selectedConversation;
          } else {
            return selectedConversation ?? null;
          }
        });

        return newConversations;
      });
    });

    socket.on("conversations", (conversationsReceived: ConversationResponse) => {
      setConversations((prevConversations) => {
        const isAlreadyAdded = prevConversations.some((conversation) => conversation.id === conversationsReceived.id);
        if (!isAlreadyAdded) {
          return [...prevConversations, conversationsReceived];
        } else {
          return prevConversations;
        }
      });

      setIsLoading(false);
    });

    return () => {
      socket.disconnect();
    };
  };

  useEffect(() => {
    if (token != "") {
      initSocketConnection();
      CardService.getAllMyCards(token)
        .then((data) => {
          const cards: CardModel[] = data.data;
          const filteredCards: CardModel[] = cards.filter(
            (card) => card.typeOfCardEnum === TypeOfCardEnum.WALLET_SHARE,
          );
          setCards(filteredCards);
        })
        .catch((error) => {
          setMessage("Error when fetching cards");
          setTimeout(() => {
            setOpenCreated(false);
          }, 4000);
        });
      CardService.getAllMyConnectedCards(token)
        .then((response) => {
          if (response.status === 200) {
            setConnectedCards(response.data);
          }
        })
        .catch(() => {
          setMessage("Error when fetching cards");
          setTimeout(() => {
            setOpenCreated(false);
          }, 4000);
        });
    }
  }, [token]);

  useEffect(() => {
    if (token != "") {
      initSocketConnection();
      CardService.getAllMyCards(token)
        .then((data) => {
          const cards: CardModel[] = data.data;
          const filteredCards: CardModel[] = cards.filter(
            (card) => card.typeOfCardEnum === TypeOfCardEnum.WALLET_SHARE,
          );
          setCards(filteredCards);
        })
        .catch((error) => {
          setMessage("Error when fetching cards");
          setTimeout(() => {
            setOpenCreated(false);
          }, 4000);
        });
      CardService.getAllMyConnectedCards(token)
        .then((response) => {
          if (response.status === 200) {
            setConnectedCards(response.data);
          }
        })
        .catch(() => {
          setMessage("Error when fetching cards");
          setTimeout(() => {
            setOpenCreated(false);
          }, 4000);
        });
    }
  }, [refreshCall]);

  useEffect(() => {
    if (AuthService.getUserConnected()) {
      setToken(AuthService.getUserConnected());
    }
  }, [token]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [selectedConversation]);

  const handleCreateGroup = (event: React.FormEvent) => {
    event.preventDefault();

    const groupData = {
      name: groupName,
      cardId: selectedCardId,
    };

    // TODO: Remplacer par un call au service
    fetch("https://api.sharemee.weworkstudio.fr/group/public/create-group", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(groupData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        return response.json();
      })
      .then(() => {
        setIsModalOpen(false);
        setGroupName("");
        setSelectedCardId("");

        socket.emit("get_my_conversations");
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    const removeDuplicates = (joinedProfiles: JoinedConversationResponse[]) => {
      const map = new Map();
      joinedProfiles.forEach((joinedProfile) => {
        // Si le profil est déjà dans la map et que sa date de création est plus récente, le mettre à jour
        if (
          !map.has(joinedProfile.profile.id) ||
          map.get(joinedProfile.profile.id).createdAt < joinedProfile.createdAt
        ) {
          map.set(joinedProfile.profile.id, joinedProfile);
        }
      });

      // Retourner les valeurs de la map en tant que tableau
      return Array.from(map.values());
    };

    const interval = setInterval(() => {
      // Mettre à jour conversations
      setConversations((prevConversations) => {
        const newConversations = prevConversations.map((conversation) => {
          // Supprimer les doublons de joinedProfiles
          conversation.joinedProfiles = removeDuplicates(conversation.joinedProfiles);
          return conversation;
        });

        return newConversations;
      });

      // Mettre à jour selectedConversation
      if (selectedConversation) {
        setSelectedConversation((prevSelectedConversation) => {
          if (prevSelectedConversation) {
            // Supprimer les doublons de joinedProfiles
            prevSelectedConversation.joinedProfiles = removeDuplicates(prevSelectedConversation.joinedProfiles);
          }
          return prevSelectedConversation;
        });
      }
    }, 5000); // Exécuter toutes les 5 secondes

    // Nettoyer l'intervalle lors du démontage du composant
    return () => clearInterval(interval);
  }, [selectedConversation]);

  function getConversationName(conversation: ConversationResponse) {
    if (conversation.group) {
      return conversation.group.name;
    } else if (conversation.connectedCard) {
      return conversation.connectedCard.cardEntityOne.owner.user?.id === user.id
        ? conversation.connectedCard.cardEntityTwo.owner.usernameProfile
        : conversation.connectedCard.cardEntityOne.owner.usernameProfile;
    } else {
      return "Bienvenue sur les chats";
    }
  }

  function getIsOtherConnectedCardUserJoined(conversation: ConversationResponse) {
    const otherUserId =
      conversation.connectedCard?.cardEntityOne.owner.user?.id === user.id
        ? conversation.connectedCard?.cardEntityTwo.owner.user?.id
        : conversation.connectedCard?.cardEntityOne.owner.user?.id;

    return conversation.joinedProfiles?.some((profile) => profile.id === otherUserId) || false;
  }

  // TODO: récuprérer les bon media
  function getConversationAvatar(conversation: ConversationResponse) {
    if (conversation.group) {
      return "group";
    } else if (conversation.connectedCard) {
      return "connectedcard";
    } else {
      return "";
    }
  }

  function getConversationColor(conversation: ConversationResponse) {
    if (conversation.group) {
      return "lightblue";
    } else if (conversation.connectedCard) {
      return "lightgreen";
    } else {
      return "";
    }
  }

  const handleMessageSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (selectedConversation && messageContent.trim() == "") {
      // TODO: Ramplacer par autre chose comme message d'erreur
      return;
    }
    if (selectedConversation) {
      const message: SentMessageRequest = {
        content: messageContent,
        conversationId: selectedConversation.id,
        cardId: userCardId ?? "error",
      };

      socket.emit("send_message", message);
      setMessageContent("");
    }
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageContent(event.target.value);
  };

  const getAllConv = () => {
    console.log("");
  };

  const handleAddCardsToGroup = (event: React.FormEvent) => {
    event.preventDefault();
    if (!selectedConversation?.group) {
      // TODO: mettre une vraie erreur
      return;
    }
    GroupService.addCardToMyGroup({ groupId: selectedConversation?.group?.id, cardIdList: selectedCardIds2 }, token)
      .then((r) => {
        if (r.status === 200) {
          setMessage("Cards added to group");
          setTimeout(() => {
            setIsSecondModalOpen(false);
          }, 1000);
          socket.emit("get_my_conversations");
        } else {
          setMessage("Error when adding cards to group");
          setTimeout(() => {
            setIsSecondModalOpen(false);
          }, 1000);
        }
      })
      .catch((err) => {
        setMessage("Error when adding cards to group");
        setTimeout(() => {
          setIsSecondModalOpen(false);
        }, 1000);
      });
  };

  return (
    <div className="view--chat">
      <div className="title-view">Chat</div>
      {conversations.length > 0 && (
        <div className="chat-container-view">
          <div className="left-part">
            <div className="header-left-part">
              <div className="title-header">Conversations</div>
              <AddIcon
                style={{ color: "#fff", marginLeft: 2, cursor: "pointer" }}
                onClick={() => setIsModalOpen(true)}
              />
            </div>
            <div className="seperate" />
            <div className="container-list-chat-users">
              {isLoading ? (
                <div>Loading...</div>
              ) : conversations.length > 0 ? (
                conversations.map((conversation: ConversationResponse) => (
                  <div
                    key={conversation.id}
                    className="component-chat"
                    style={{ width: "100%", cursor: "pointer" }}
                    onClick={() => handleConversationClick(conversation)}
                  >
                    {" "}
                    <Avatar
                      {...MuiUserUtils.stringAvatar(getConversationAvatar(conversation))}
                      sx={{ width: 30, height: 30, fontSize: 14 }}
                    />
                    <div className="title-name">{getConversationName(conversation)}</div>
                  </div>
                ))
              ) : (
                <div>No conversations found</div>
              )}
            </div>
          </div>
          <div className="seperate-height" />
          <div className="right-part">
            <div className="header-chat">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="title-chat" style={{ marginRight: "20px" }}>
                  {getConversationName(selectedConversation)}
                </div>
                {/*
                       {selectedConversation.connectedCard && (
                        <Tooltip title={getIsOtherConnectedCardUserJoined(selectedConversation) ? "Connected" : "Not Connected"}>
                          <div style={{
                            height: "10px",
                            width: "10px",
                            backgroundColor: getIsOtherConnectedCardUserJoined(selectedConversation) ? "red" : "green",
                            borderRadius: "50%",
                          }} />
                        </Tooltip>
                    )}
                    */}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {selectedConversation.group && (
                  <GroupModal
                    group={selectedConversation.group}
                    token={token}
                    actualUser={user}
                    joinedProfiles={selectedConversation.joinedProfiles ?? []}
                    call={refreshCall}
                    setCall={getAllConv()}
                  />
                )}
                {selectedConversation.group && availableCards.length > 0 && (
                  <AddIcon style={{ color: "#fff", cursor: "pointer" }} onClick={() => handleSecondModalOpen()} />
                )}
              </div>
            </div>
            <div className="seperate" />
            <div className="container-right-part">
              <div className="space-message">
                {(selectedConversation?.messages ? [...selectedConversation.messages] : []).reverse().map((message) => {
                  const messageUser: MesssageUser = {
                    message: message.content,
                    createdAt: message.createdAt.toString(),
                    photoURL: "https://picsum.photos/200/300",
                    displayName: message.author.owner.usernameProfile,
                  };
                  return message.author.id === userCardId ? (
                    <MessageRight {...messageUser} key={message.id} />
                  ) : (
                    <MessageLeft {...messageUser} key={message.id} />
                  );
                })}
                <div ref={bottomRef} />
              </div>
              <div className="input-container">
                <form onSubmit={handleMessageSubmit}>
                  <input type="text" value={messageContent} onChange={handleMessageChange} />
                  <button type="submit">
                    <SendIcon sx={{ color: "#fff" }} />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {conversations.length === 0 && (
        <div style={{ position: "relative", left: "40%", top: "300px" }}>
          <Button
            style={{ background: "rgb(34, 10, 110)", color: "#fff", marginLeft: 2, cursor: "pointer" }}
            onClick={() => setIsModalOpen(true)}
          >
            Crée un groupe
          </Button>
        </div>
      )}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <form onSubmit={handleCreateGroup}>
            <TextField
              margin="normal"
              fullWidth
              id="group-name"
              label="Group Name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
            <TextField
              select
              margin="normal"
              fullWidth
              label="Card"
              value={selectedCardId}
              onChange={(e) => setSelectedCardId(e.target.value)}
            >
              {cards.map((card: CardModel) => (
                <MenuItem key={card.id} value={card.id}>
                  {card.owner.usernameProfile} - {card.owner.roleProfile} - {card.typeOfCardEnum}
                </MenuItem>
              ))}
            </TextField>
            <Button variant="contained" type="submit">
              Create Group
            </Button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={isSecondModalOpen}
        onClose={handleSecondModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <form onSubmit={handleAddCardsToGroup}>
            <TextField
              select
              margin="normal"
              fullWidth
              label="Cards"
              value={selectedCardIds2}
              onChange={handleCardSelectionChange}
              SelectProps={{
                multiple: true,
              }}
            >
              {availableCards.map((card: CardModel) => (
                <MenuItem key={card.id} value={card.id}>
                  {card.owner.usernameProfile} - {card.owner.roleProfile} - {card.typeOfCardEnum}
                </MenuItem>
              ))}
            </TextField>
            <Button variant="contained" type="submit">
              Add Cards to Group
            </Button>
          </form>
        </Box>
      </Modal>
      <Snackbar open={openCreated} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Chat;
