import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import isEmpty from "../../utils/isempty.utils";
import CardService from "../../services/api/card.service";

const RedirectCount = () => {
  const { id, url } = useParams<string>();
  const [token, setToken] = useState<any>("");

  function replacePlusWithSlash(input: string): string {
    return input.replace(/\+/g, "/");
  }
  function redirectToExternalSite(url: string | undefined, id: string | undefined): void {
    if (url != undefined && id != undefined) {
      CardService.addViewCount(id).then((r) => {
        if (r.status == 204) {
          window.location.href = "https://" + replacePlusWithSlash(url);
        }
      });
    } else {
      window.location.href = "https://google.com";
    }
  }
  return (
    <div className="view--redirect">
      <div className="redirect-modal">
        <div className="title">Allow to redirect social network</div>
        <div className="button-container">
          <div className="button">Cancel</div>
          <div className="button" onClick={() => redirectToExternalSite(url, id)}>
            Continue
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedirectCount;
