import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Snackbar,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { UserModel } from "../../../models/entities/user/user.response.model";
import UserService from "../../../services/api/user.service";
import { getMe } from "../../../actions/API/user.action";
import AuthService from "../../../services/api/auth.service";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../utils/isempty.utils";
import CompagnyService from "../../../services/api/compagny.service";
import { UpdateCompanyRequest } from "../../../models/entities/compagny/request/update-company.request";
import { GET_COMPANY_ME, getMeCompagny } from "../../../actions/API/company.actions";
import { useNavigate } from "react-router-dom";
import { getMeCompagnyMedia } from "../../../actions/API/media.company.action";
import AddIcon from "@mui/icons-material/Add";
import MediaService from "../../../services/api/media.service";

const GeneralEntreprise = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const company = useSelector((state: any) => state.companyReducer);
  const [values, setValues] = useState({
    name: "",
    email: "",
    siret: "",
    phone: "",
    description: "",
  });
  const [mediaCompany, setMediaCompany] = useState<any>(false);

  const [openFailed, setOpenFailed] = useState(false);
  const [openCreated, setOpenCreated] = useState(false);
  const [message, setMessage] = useState("");
  const [token, setToken] = useState<any>("");
  const lang = useSelector((state: any) => state.langReducer);
  const [urlAvatar, setUrlAvatar] = useState<any>("");
  const [urlBanner, setUrlBanner] = useState<any>("");
  const [fileBanner, setFileBanner] = useState<any>("");
  const [selectedImage, setSelectedImage] = useState(null);

  const handleChange = useCallback((event: { target: { name: any; value: any } }) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const updateCompany = () => {
    console.log("cc");
    const dataUpdateCompagny: UpdateCompanyRequest = {
      id: company.id,
      occupationListId: [],
      profileId: company.profileId,
      updateCompanyDto: {
        name: values.name,
        siret: values.siret,
        description: values.description,
        banner_url: "",
        address: "",
        phone: values.phone,
        email: values.email,
      },
    };
    requestModifyApi(dataUpdateCompagny, token);
  };
  const deleteCompany = () => {
    CompagnyService.deleteCompany(company.id, token)
      .then((r) => {
        if (r.status === 204) {
          navigate("/logout");
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("Impossible de supprimé l'Occupation");
        setTimeout(() => {
          setOpenFailed(false);
        }, 1000);
      });
  };

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };

  const requestModifyApi = (data: UpdateCompanyRequest, token: string) => {
    CompagnyService.updateCompany(company.id, data, token)
      .then((r) => {
        if (r.status === 200) {
          CompagnyService.getCompanyByOwner(token)
            .then((r) => {
              if (r.status === 200) {
                setValues({
                  name: r.data.name,
                  email: r.data.email,
                  siret: r.data.siret,
                  phone: r.data.phone,
                  description: r.data.description,
                });
                setOpenCreated(true);
                setMessage("entreprise mise a jours");
                setTimeout(() => {
                  setOpenCreated(false);
                }, 4000);
              } else {
                setOpenFailed(true);
                setMessage("entreprise non mise a jour");
                setTimeout(() => {
                  setOpenFailed(false);
                }, 4000);
              }
            })
            .catch((err) => console.log(""));
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("entreprise non mise a jour");
        setTimeout(() => {
          setOpenFailed(false);
        }, 4000);
      });
  };

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleUpload = () => {
    if (!selectedImage) {
      setOpenFailed(true);
      setMessage("Aucune image selectionné");
      setTimeout(() => {
        setOpenFailed(false);
      }, 4000);
      return;
    }

    const formadata = new FormData();
    formadata.append("file", selectedImage);
    MediaService.addAvatarCompany(company.id, formadata, token)
      .then((r) => {
        if (r.status === 201) {
          setOpenCreated(true);
          dispatch(getMeCompagny(token));
          setMediaCompany(!mediaCompany);
          setMessage("media ajouter");
          setTimeout(() => {
            setOpenCreated(false);
          }, 1000);
        }
      })
      .catch((reason) => {
        setOpenFailed(true);
        setMessage("entreprise non mise a jour");
        setTimeout(() => {
          setOpenFailed(false);
        }, 4000);
      });
  };

  const handleImageChangeBanner = (event: any) => {
    const file = event.target.files[0];
    setFileBanner(file);
    if (fileBanner) {
      handleUploadBanner();
    } else {
      setOpenFailed(true);
      setMessage("Aucune image selectionné");
      setTimeout(() => {
        setOpenFailed(false);
      }, 4000);
      return;
    }
  };

  const handleUploadBanner = () => {
    if (!fileBanner) {
      setOpenFailed(true);
      setMessage("Aucune image selectionné");
      setTimeout(() => {
        setOpenFailed(false);
      }, 4000);
      return;
    }
    const formadata = new FormData();
    formadata.append("file", fileBanner);
    MediaService.addBannerCompany(company.id, formadata, token)
      .then((r) => {
        if (r.status === 201) {
          console.log("media ajouter");
          setOpenCreated(true);
          dispatch(getMeCompagny(token));
          setMediaCompany(!mediaCompany);
          setMessage("media ajouter");
          setTimeout(() => {
            setOpenCreated(false);
          }, 1000);
        }
      })
      .catch((reason) => {
        setOpenFailed(true);
        setMessage("entreprise non mise a jour");
        setTimeout(() => {
          setOpenFailed(false);
        }, 4000);
      });
  };

  useEffect(() => {
    if (!isEmpty(company)) {
      setValues({
        name: company.name,
        email: company.email,
        siret: company.siret,
        phone: company.phone,
        description: company.description,
      });
    } else {
      setValues({
        name: "",
        email: "",
        siret: "",
        phone: "",
        description: "",
      });
    }
  }, [company]);

  useEffect(() => {
    if (!isEmpty(AuthService.getUserConnected())) {
      setToken(AuthService.getUserConnected());
    }
  }, [token]);

  useEffect(() => {
    if (company.avatarMedia != null) {
      MediaService.getMediaWithId(company.avatarMedia, token).then((r) => {
        if (r.status === 200) {
          setUrlAvatar(r.data.url);
        }
      });
    }
    if (company.bannerMedia != null) {
      MediaService.getMediaWithId(company.bannerMedia, token).then((r) => {
        if (r.status === 200) {
          setUrlBanner(r.data.url);
        }
      });
    }
  }, [company]);

  return (
    <>
      <div className="component--general-entreprise">
        <Card sx={{ minWidth: 275, marginBottom: 5, borderRadius: 3 }}>
          <CardContent>
            <div className="container-card">
              <div className="title-card-container">
                <div className="title-card">{lang === "FR" ? "Informations" : "Basic Infomations"}</div>
                <div className="file-drop-area">
                  {urlAvatar === "" && (
                    <label style={{ cursor: "pointer", position: "relative" }}>
                      <div className="circle" />
                      <AddIcon
                        style={{
                          position: "absolute",
                          borderRadius: "50%",
                          background: "#FFF",
                          color: "rgb(34, 10, 110)",
                          left: "70px",
                          top: "60px",
                          height: "30px",
                          width: "35px",
                        }}
                      />
                      <input accept="image/*" onChange={handleImageChange} type="file" />
                    </label>
                  )}
                  {urlAvatar != "" && (
                    <label style={{ cursor: "pointer", position: "relative" }}>
                      <img className="circle" style={{ background: "transparent" }} src={urlAvatar} alt="" />
                      <AddIcon
                        style={{
                          position: "absolute",
                          borderRadius: "50%",
                          background: "#FFF",
                          color: "rgb(34, 10, 110)",
                          left: "70px",
                          top: "60px",
                          height: "30px",
                          width: "35px",
                        }}
                      />
                      <input onChange={handleImageChange} accept="image/*" type="file" />
                    </label>
                  )}
                  <div className="save" onClick={() => handleUpload()}>
                    Save
                  </div>
                </div>
              </div>
              <div className="left-part">
                {urlBanner === "" && (
                  <Grid xs={12} md={6} sx={{ marginBottom: 3 }}>
                    <div className="title-upload">Ajouter une Banniere</div>
                    <div className="file-drop-area">
                      <span className="fake-btn">Choisir son image</span>
                      <span className="file-msg">or drag and drop image here</span>
                      <input className="file-input" accept="image/*" onChange={handleImageChangeBanner} type="file" />
                    </div>
                  </Grid>
                )}

                {urlBanner != "" && (
                  <Grid xs={12} md={6} sx={{ marginBottom: 3 }}>
                    <div className="title-upload">Ajouter une Banniere</div>
                    <div className="file-drop-area">
                      <img
                        className="circle"
                        style={{ background: "transparent", height: "200px", width: "99%" }}
                        src={urlBanner}
                        alt=""
                      />
                      <AddIcon
                        style={{
                          position: "relative",
                          borderRadius: "50%",
                          background: "#FFF",
                          color: "rgb(34, 10, 110)",
                          right: "40px",
                          top: "80px",
                          height: "30px",
                          width: "35px",
                        }}
                      />
                      <input className="file-input" accept="image/*" onClick={handleImageChangeBanner} type="file" />
                    </div>
                  </Grid>
                )}

                <Grid xs={12} md={6} sx={{ marginBottom: 3 }}>
                  <TextField
                    fullWidth
                    label={lang === "FR" ? "Nom" : "Name"}
                    name="name"
                    onChange={handleChange}
                    required
                    value={values.name}
                  />
                </Grid>
                <Grid xs={12} md={6} sx={{ marginBottom: 3 }}>
                  <TextField
                    fullWidth
                    label={lang === "FR" ? "Téléphone" : "Phone"}
                    name="phone"
                    onChange={handleChange}
                    required
                    value={values.phone}
                  />
                </Grid>
                <Grid xs={12} md={6} sx={{ marginBottom: 3 }}>
                  <TextField
                    fullWidth
                    label={lang === "FR" ? "Mail" : "Mail"}
                    name="email"
                    onChange={handleChange}
                    required
                    value={values.email}
                  />
                </Grid>
                <Grid xs={12} md={6} sx={{ marginBottom: 3 }}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    onChange={handleChange}
                    required
                    value={values.description}
                  />
                </Grid>
                <button className="button-save" onClick={() => updateCompany()}>
                  {lang === "FR" ? "Sauvegarder" : "Save"}
                </button>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275, marginBottom: 5, borderRadius: 3 }}>
          <CardContent>
            <div className="container-card">
              <div className="title-card">{lang === "FR" ? "Supprimé le Compte" : "Delete Account"}</div>
              <div className="left-part">
                <div className="content">
                  {lang === "FR"
                    ? "Supprimez votre compte et toutes vos données sources. Cette opération est irréversible."
                    : "Delete your account and all of your source data. This is irreversible."}
                </div>
                <div className="button-delete" onClick={() => deleteCompany}>
                  {lang === "FR" ? "Supprimé" : "Delete"}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <Snackbar open={openCreated} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GeneralEntreprise;
