import React from "react";
import { Avatar } from "@mui/material";

export interface MesssageUser {
  message: string;
  createdAt: string;
  photoURL: string;
  displayName: string;
}

export const MessageLeft = (props: MesssageUser) => {
  const message = props.message ? props.message : "no message";
  const timestamp = props.createdAt ? props.createdAt : "";
  const photoURL = props.photoURL ? props.photoURL : "dummy.js";
  const displayName = props.displayName ? props.displayName : "名無しさん";
  return (
    <div className="messageRow">
      <Avatar alt={displayName} className="orange" src={photoURL}></Avatar>
      <div style={{ width: "100%" }}>
        <div className="displayName">{displayName}</div>
        <div className="messageBlue">
          <div>
            <p className="messageContent">{message}</p>
          </div>
          <div className="messageTimeStampRight">{timestamp}</div>
        </div>
      </div>
    </div>
  );
};

export const MessageRight = (props: MesssageUser) => {
  const message = props.message ? props.message : "no message";
  const timestamp = props.createdAt ? props.createdAt : "";
  return (
    <div className="messageRowRight">
      <div className="messageOrange">
        <p className="messageContent">{message}</p>
        <div className="messageTimeStampRight">{timestamp}</div>
      </div>
    </div>
  );
};
