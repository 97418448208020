import React, { useEffect } from "react";
import { ICardUser } from "../../models/component/user/card-user.model";
import { Avatar } from "@mui/material";
import MuiUserUtils from "../../utils/mui.user.utils";
import { useSelector } from "react-redux";
import { CardModel } from "../../models/entities/card/response/card.response";

const CardUser = (props: ICardUser) => {
  const { imageP, name, cards, cardShares, cardGetting, job, viewCard } = props;
  const lang = useSelector((state: any) => state.langReducer);

  useEffect(() => {
    console.log(cards);
  }, []);

  const returnMedia = (value: CardModel) => {
    if (value.socialNetwork != null && value.typeOfCardEnum === "SOCIAL_NETWORK") {
      if (value.socialNetwork.icon === "youtube") {
        return "/assets/logo/you.png";
      } else if (value.socialNetwork.icon === "facebook") {
        return "/assets/logo/fb.png";
      } else if (value.socialNetwork.icon === "github") {
        return "/assets/logo/git.png";
      } else if (value.socialNetwork.icon === "snapchat") {
        return "/assets/logo/snap.png";
      } else if (value.socialNetwork.icon === "instagram") {
        return "/assets/logo/insta.png";
      } else if (value.socialNetwork.icon === "linkedin") {
        return "/assets/logo/in.png";
      }
    } else {
      if (value.typeOfCardEnum === "V_CARD") {
        return "/assets/logo/contacts.png";
      } else if (value.typeOfCardEnum === "WEBSITE") {
        return "/assets/logo/web.png";
      } else {
        return "/assets/logo/logo.png";
      }
    }
  };

  return (
    <div className="card-user-component">
      <Avatar
        {...MuiUserUtils.stringAvatar("Kent Dodds")}
        sx={{ backgroundColor: "rgb(99, 115, 129)", borderRadius: "50%", width: 80, height: 80, fontSize: 40 }}
      />
      <div className="title-name">{name}</div>
      <div className="subtitle-job">{job}</div>
      <div className="container-type-card">
        {cards &&
          cards.map((value, index) => <img className="logo-card-poss" key={index} src={returnMedia(value)} alt="" />)}
      </div>
      <div className="liner" />
      <div className="global-container-stats">
        <div className="container-stats">
          <div className="title-stats">{lang === "FR" ? "Distribuée" : "Shares"}</div>
          <div className="number-stats">{cardShares}</div>
        </div>

        <div className="container-stats">
          <div className="title-stats">{lang === "FR" ? "Récupérer" : "Recover"}</div>
          <div className="number-stats">{cardGetting}</div>
        </div>

        <div className="container-stats">
          <div className="title-stats">Vue</div>
          <div className="number-stats">{viewCard}</div>
        </div>
      </div>
    </div>
  );
};

export default CardUser;
