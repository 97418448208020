import React from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { AccountProfile } from "../../components/account/account-profile";
import { AccountProfileDetails } from "../../components/account/account-profile-details";

const Profile = () => {
  return (
    <div className="view--profile">
      <Typography variant="h4" sx={{ marginBottom: 5, color: "rgb(34, 10, 110)" }}>
        Comptes
      </Typography>
      <div className="container-profile">
        <div className="left-part">
          <AccountProfile />
        </div>
        <div className="right-part">
          <AccountProfileDetails />
        </div>
      </div>
    </div>
  );
};

export default Profile;
