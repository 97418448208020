import http from "../http-common";
import { MarkNotificationAsReadRequest } from "../../models/entities/notification/request/mark-notification-as-read.request";
import { CreateNotificationAdminRequest } from "../../models/entities/notification/request/create-notification-admin.request";
import { MarkNotificationAsReadRequestALL } from "../../models/entities/notification/response/markNotificationAsReadRequestAll.model";

const getAllNotifications = (token: string) => {
  return http.get<any>("/notification/admin/get-all-notifications", { headers: { Authorization: "Bearer " + token } });
};

const getAllNotificationsForUser = (token: string) => {
  return http.get<any>("/notification/public/get-all-notifications-for-user", {
    headers: { Authorization: "Bearer " + token },
  });
};
const getUnreadNotificationsForUser = (token: string) => {
  return http.get<any>("/notification/public/get-unread-notifications-for-user", {
    headers: { Authorization: "Bearer " + token },
  });
};

const markNotificationAsRead = (data: MarkNotificationAsReadRequest, token: string) => {
  return http.put<any>("/notification/public/mark-notification-as-read", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const markNotificationAsReadAll = (data: MarkNotificationAsReadRequestALL, token: string) => {
  return http.put<any>("/notification/public/mark-notification-as-read-tab", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const softRemoveNotification = (notificationId: string, token: string) => {
  return http.delete<any>(`/notification/admin/remove-notification/${notificationId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const restoreNotification = (notificationId: string, token: string) => {
  return http.put<any>(
    `/notification/admin/restore-notification/${notificationId}`,
    {},
    { headers: { Authorization: "Bearer " + token } },
  );
};

const createNotification = (data: CreateNotificationAdminRequest, token: string) => {
  return http.post<any>("/notification/admin/create-notification", { headers: { Authorization: "Bearer " + token } });
};

const NotificationService = {
  getAllNotifications,
  getAllNotificationsForUser,
  getUnreadNotificationsForUser,
  markNotificationAsRead,
  softRemoveNotification,
  restoreNotification,
  createNotification,
  markNotificationAsReadAll,
};

export default NotificationService;
