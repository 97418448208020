import {
  GET_NOTIFICATIONS_ROLE
} from "../../actions/API/notification.action";

const initialState = {};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
  case GET_NOTIFICATIONS_ROLE:
    return action.payload;
  default:
    return state;
  }
}
