import React, { SetStateAction, useState } from "react";
import CardUserMinimal from "../../components/card_user_minimal/CardUserMinimal";
import List from "@mui/material/List";
import { Link } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import ApartmentIcon from "@mui/icons-material/Apartment";
import FolderIcon from "@mui/icons-material/Folder";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Shop2Icon from "@mui/icons-material/Shop2";
import VideoChatIcon from "@mui/icons-material/VideoChat";
import ForumIcon from "@mui/icons-material/Forum";
import { Collapse, ListItem } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";

const DrawerNav = () => {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [open, setOpen] = React.useState(true);
  const lang = useSelector((state: any) => state.langReducer);
  const handleClick = () => {
    setOpen(!open);
  };
  const handleListItemClick = (event: any, index: SetStateAction<number>) => {
    setSelectedIndex(index);
  };
  return (
    <div className="component-drawer-nav">
      <img src="/assets/logo/logo.png" style={{ width: 50, height: 50, borderRadius: 10, marginBottom: 20 }} alt="" />
      <CardUserMinimal />
      <div className="title-nav">GENERAL</div>
      <List>
        <ListItem key={"App"} disablePadding>
          <Link to={"/dashboard"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgba(34, 10, 110,0.12)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <SpaceDashboardIcon />
              </ListItemIcon>
              <ListItemText primary={"App"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key={"Analytics"} disablePadding>
          <Link to={"/analytics"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <QueryStatsIcon />
              </ListItemIcon>
              <ListItemText primary={lang === "FR" ? "Analyse" : "Analytics"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key={"Card Design"} disablePadding>
          <Link to={"/card-design"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <DesignServicesIcon />
              </ListItemIcon>
              <ListItemText primary={lang === "FR" ? "Cartes Design" : "Card Design"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key={"Chat"} disablePadding>
          <Link to={"/chat"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 4}
              onClick={(event) => handleListItemClick(event, 4)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <ForumIcon />
              </ListItemIcon>
              <ListItemText primary={"Chat"} />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
      <div className="title-nav">MANAGEMENT</div>
      <List>
        <ListItem key={"Enterprise"} disablePadding>
          <Link to={"/enterprise"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 6}
              onClick={(event) => handleListItemClick(event, 6)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <ApartmentIcon />
              </ListItemIcon>
              <ListItemText primary={lang === "FR" ? "Enterprise" : "Company"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key={"Utilisateurs"} disablePadding>
          <ListItemButton
            selected={selectedIndex === 7}
            onClick={handleClick}
            sx={{
              "&$selected": {
                backgroundColor: "rgba(145, 158, 171, 0.12)",
                color: "rgb(34, 10, 110)",
                borderRadius: 3,
                "& .MuiListItemIcon-root": {
                  color: "rgb(34, 10, 110)",
                },
              },
              "&:hover": {
                backgroundColor: "rgba(145, 158, 171, 0.12)",
                color: "rgb(34, 10, 110)",
                borderRadius: 3,
                "& .MuiListItemIcon-root": {
                  color: "rgb(34, 10, 110)",
                },
              },
            }}
          >
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary={lang === "FR" ? "Salarié" : "Employee"} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link to={"/card-users"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
              <ListItemButton
                selected={selectedIndex === 8}
                onClick={(event) => handleListItemClick(event, 8)}
                sx={{
                  pl: 4,
                  "&$selected": {
                    backgroundColor: "rgba(145, 158, 171, 0.12)",
                    color: "rgb(34, 10, 110)",
                    borderRadius: 3,
                    "& .MuiListItemIcon-root": {
                      color: "rgb(34, 10, 110)",
                    },
                  },
                  "&:hover": {
                    backgroundColor: "rgba(145, 158, 171, 0.12)",
                    color: "rgb(34, 10, 110)",
                    borderRadius: 3,
                    "& .MuiListItemIcon-root": {
                      color: "rgb(34, 10, 110)",
                    },
                  },
                }}
              >
                <ListItemIcon>
                  <img src="/assets/icons/navbar/ic_cart.svg" />
                </ListItemIcon>
                <ListItemText primary={lang === "FR" ? "Cartes" : "Cards"} />
              </ListItemButton>
            </Link>
            <Link to={"/list-users"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
              <ListItemButton
                selected={selectedIndex === 9}
                onClick={(event) => handleListItemClick(event, 9)}
                sx={{
                  pl: 4,
                  "&$selected": {
                    backgroundColor: "rgba(145, 158, 171, 0.12)",
                    color: "rgb(34, 10, 110)",
                    borderRadius: 3,
                    "& .MuiListItemIcon-root": {
                      color: "rgb(34, 10, 110)",
                    },
                  },
                  "&:hover": {
                    backgroundColor: "rgba(145, 158, 171, 0.12)",
                    color: "rgb(34, 10, 110)",
                    borderRadius: 3,
                    "& .MuiListItemIcon-root": {
                      color: "rgb(34, 10, 110)",
                    },
                  },
                }}
              >
                <ListItemIcon>
                  <img src="/assets/icons/navbar/ic_user.svg" />
                </ListItemIcon>
                <ListItemText primary={lang === "FR" ? "Liste" : "List"} />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <ListItem key={"File Manager"} disablePadding>
          <Link to={"/file-manager"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 10}
              onClick={(event) => handleListItemClick(event, 10)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary={lang === "FR" ? "Documents" : "File Manager"} />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>

      <div className="title-nav expt"></div>
      <List>
        <ListItem key={"Logout"} disablePadding>
          <Link to={"/logout"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
            <ListItemButton
              selected={selectedIndex === 11}
              onClick={(event) => handleListItemClick(event, 7)}
              sx={{
                "&$selected": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
                "&:hover": {
                  backgroundColor: "rgba(145, 158, 171, 0.12)",
                  color: "rgb(34, 10, 110)",
                  borderRadius: 3,
                  "& .MuiListItemIcon-root": {
                    color: "rgb(34, 10, 110)",
                  },
                },
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={lang === "FR" ? "Déconnexion" : "Logout"} />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
    </div>
  );
};

export default DrawerNav;
