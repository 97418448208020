import React, { useState } from "react";

interface ColorPickerProps {
  onChange: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ onChange }) => {
  const [selectedColor, setSelectedColor] = useState("");

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const color = event.target.value;
    setSelectedColor(color);
    onChange(color);
  };

  return (
    <div>
      <input
        style={{ width: "400px", border: "none", borderRadius: "5px", cursor: "pointer" }}
        type="color"
        value={selectedColor}
        onChange={handleColorChange}
      />
    </div>
  );
};

export default ColorPicker;
