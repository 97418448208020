/**
 * @Name TypeOfCardEnum
 * @description
 * This is an enum that contains the types of cards that can be created.
 * @enum {string}
 * @property {string} SOCIAL_NETWORK - The card is a social network card.
 * @property {string} WEBSITE - The card is a website card.
 * @property {string} WALLET_SHARE - The card is a wallet share card.
 */
export enum TypeOfCardEnum {
  SOCIAL_NETWORK = "SOCIAL_NETWORK",
  WEBSITE = "WEBSITE",
  WALLET_SHARE = "WALLET_SHARE",
  V_CARD = "V_CARD",
}
