import React, { useCallback, useEffect, useState } from "react";
import { Alert, Card, CardContent, Snackbar, TextField, Unstable_Grid2 as Grid } from "@mui/material";
import CompagnyService from "../../../services/api/compagny.service";
import { getMeCompagny } from "../../../actions/API/company.actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UpdateCompanyRequest } from "../../../models/entities/compagny/request/update-company.request";
import UserService from "../../../services/api/user.service";
import { da } from "date-fns/locale";
import { UpdateUserCredentialDto } from "../../../models/entities/user/dto/update-user-credential.dto";
import AuthService from "../../../services/api/auth.service";
import isEmpty from "../../../utils/isempty.utils";
import { GET_ME, getMe } from "../../../actions/API/user.action";

const Security = () => {
  const [values, setValues] = useState({
    password: "",
    newPassword: "",
  });

  const [disabledB, setDisabledB] = useState(false);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const company = useSelector((state: any) => state.companyReducer);
  const [openFailed, setOpenFailed] = useState(false);
  const [openCreated, setOpenCreated] = useState(false);
  const [message, setMessage] = useState("");
  const [token, setToken] = useState<any>("");
  const lang = useSelector((state: any) => state.langReducer);
  const user = useSelector((state: any) => state.userReducer);
  const [messageError, setMessageError] = useState<any>("");
  const handleChange = useCallback((event: { target: { name: any; value: any } }) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmitSave = () => {
    if (disabledB === true) {
      setDisabledB(false);
      if (values.newPassword != "") {
        updatePassword();
      }
    } else {
      setDisabledB(true);
    }
  };

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
    setOpenCreated(false);
  };

  const updatePassword = () => {
    const dataUpdateCompagny: UpdateUserCredentialDto = {
      username: user.username,
      password: values.password,
      newPassword: values.newPassword,
    };
    UserService.updatePassword(user.id, dataUpdateCompagny, token)
      .then((r) => {
        if (r.status === 204) {
          dispatch(getMe(token));
          UserService.getMe(token)
            .then((r) => {
              if (r.status === 200) {
                setValues({
                  password: r.data.password,
                  newPassword: "",
                });
                setDisabledB(false);
                setOpenCreated(true);
                setMessage("password mise a jours");
                setTimeout(() => {
                  setOpenCreated(false);
                }, 1000);
              }
            })
            .catch((err) => console.log(""));
        }
      })
      .catch((err) => {
        setDisabledB(true);
        if (lang === "FR") {
          setMessageError(
            "Mot de passe incorrect. Le mot de passe doit contenir au moins 4 caractères, au moins une lettre majuscule, une lettre minuscule et un chiffre",
          );
        } else {
          setMessageError(err.response.data.message);
        }
        setMessage("password non mise a jour");
        setTimeout(() => {
          setOpenFailed(false);
        }, 1000);
      });
  };

  useEffect(() => {
    if (!isEmpty(AuthService.getUserConnected())) {
      setToken(AuthService.getUserConnected());
      dispatch(getMe(AuthService.getUserConnected()));
    }
  }, [token]);

  return (
    <>
      <div className="component--security">
        <Card sx={{ minWidth: 275, marginBottom: 5, borderRadius: 3 }}>
          <CardContent style={{ display: "flex", flexDirection: "column" }}>
            <div className="container-card-security">
              <div className="title-card">{lang === "FR" ? "Changer de mot de passe" : "Change Password"}</div>
              <div className="container-card-input">
                {disabledB === false && (
                  <Grid sx={{ width: "calc(80% - 10px)", marginRight: 2 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      fullWidth
                      label={lang === "FR" ? "Mot de Passe" : "Password"}
                      name="password"
                      type={"password"}
                      onChange={handleChange}
                      required
                      value={values.password}
                      disabled
                    />
                  </Grid>
                )}
                {disabledB === true && (
                  <Grid sx={{ width: "calc(80% - 10px)", marginRight: 2 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      fullWidth
                      label={lang === "FR" ? "Mot de Passe" : "Password"}
                      name="password"
                      type={"password"}
                      onChange={handleChange}
                      required
                      value={values.password}
                    />
                  </Grid>
                )}
                {disabledB === false && (
                  <Grid sx={{ width: "calc(80% - 10px)", marginRight: 2 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      fullWidth
                      label={lang === "FR" ? "Nouveau Mot de Passe" : "New Password"}
                      name="password"
                      type={"password"}
                      onChange={handleChange}
                      required
                      value={values.newPassword}
                      disabled
                    />
                  </Grid>
                )}
                {disabledB === true && (
                  <Grid sx={{ width: "calc(80% - 10px)", marginRight: 2 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      fullWidth
                      label={lang === "FR" ? "Nouveau Mot de Passe" : "New Password"}
                      name="newPassword"
                      type={"newPassword"}
                      onChange={handleChange}
                      required
                      value={values.newPassword}
                    />
                  </Grid>
                )}
                <div className="button-save" onClick={() => handleSubmitSave()}>
                  {disabledB === false && <>{lang === "FR" ? "Changer" : "edit"}</>}
                  {disabledB === true && <>{lang === "FR" ? "Sauvegarder" : "save"}</>}
                </div>
              </div>
            </div>
            {messageError != "" && <div style={{ color: "red", fontSize: "14px" }}>{messageError}</div>}
          </CardContent>
        </Card>
      </div>
      <Snackbar open={openCreated} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Security;
