import React, { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Auth } from "../../models/auth.model";
import resolver from "../../resolver/auth.resolver";
import AuthService from "../../services/api/auth.service";
import isEmpty from "../../utils/isempty.utils";
import { getMe } from "../../actions/API/user.action";
import { CreateCompanyRequest } from "../../models/entities/compagny/request/create-company.request";
import { CreateCompanyDto } from "../../models/entities/compagny/dto/create-company.dto";
import CompanyService from "../../services/api/compagny.service";
import { UserModel } from "../../models/entities/user/user.response.model";
import { GET_COMPANY_ME, getMeCompagny } from "../../actions/API/company.actions";
import { ProfileModel } from "../../models/entities/profile/response/profile.response";
import CompagnyService from "../../services/api/compagny.service";

const CreateCompany = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateCompanyDto>();

  const [openFailed, setOpenFailed] = useState(false);
  const [message, setMessage] = useState("");
  const [token, setToken] = useState<any>("");
  const [profileId, setProfileId] = useState("");
  const user = useSelector((state: any) => state.userReducer);
  const company = useSelector((state: any) => state.company);

  useEffect(() => {
    if (!isEmpty(AuthService.getUserConnected())) {
      setToken(AuthService.getUserConnected());
      CompagnyService.getCompanyByOwner(token)
        .then((r) => {
          if (r.status === 200) {
            navigate("/dashboard");
          }
        })
        .catch((err) => console.log(""));
      if (isEmpty(user)) {
        dispatch(getMe(token));
      }
    }
  }, [token]);

  useEffect(() => {
    returnProfileIdCompany(user);
  }, [user]);

  const returnProfileIdCompany = (user: any) => {
    if (!isEmpty(user.profiles)) {
      user.profiles.forEach((profile: ProfileModel) => {
        if (profile.roleProfile === "COMPANY") {
          setProfileId(profile.id);
        }
      });
    }
  };

  const onSubmit = handleSubmit((data) => {
    const dataCreateCompany: CreateCompanyRequest = {
      createCompanyDto: data,
      occupationListId: [],
      profileId: profileId,
    };
    CompanyService.createCompany(dataCreateCompany, token)
      .then((r) => {
        if (r.status === 201) {
          CompagnyService.getCompanyByOwner(token)
            .then((r) => {
              if (r.status === 200) {
                navigate("/dashboard");
                sessionStorage.setItem("companyId", r.data.id);
              }
            })
            .catch((err) => console.log(""));
        }
      })
      .catch((r) => {
        setOpenFailed(true);
        setMessage("Votre company n'a pas etait mis a jours contactez l'administration");
        setTimeout(() => {
          setOpenFailed(false);
        }, 2000);
      });
    //sessionStorage.setItem("token", "r.data.currentHashedRefreshToken");
  });

  const handleCloseSnackbar = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };
  return (
    <div className="view-login">
      <img className="app-logo" src="/assets/logo/logo.png" alt="" />
      <div className="body exptB">
        <div className="left-part exptl">
          <div className="title">Completez vos informations entreprise</div>
          <img className="login-img" src="/assets/illustrations/info_company.svg" alt="" />
        </div>
        <div className="right-part exptr">
          <div className="title exptT">Informations</div>
          <form onSubmit={onSubmit} className="container-form">
            <input className="input-form" {...register("name")} placeholder={"Nom de l'entreprise"} type="text" />
            <input className="input-form" type="text" placeholder={"siret"} {...register("siret")} />
            <input className="input-form" type="text" placeholder={"address"} {...register("address")} />
            <input className="input-form" type="text" placeholder={"phone"} {...register("phone")} />
            <textarea
              className="input-form"
              style={{ height: 200 }}
              placeholder={"description"}
              {...register("description")}
            />
            <button className="button-submit">Completez vos informations</button>
          </form>
        </div>
      </div>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CreateCompany;
