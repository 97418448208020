import { useEffect, useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from "@mui/material";
import MuiUserUtils from "../../utils/mui.user.utils";
import { Link } from "react-router-dom";
import {  useSelector } from "react-redux";
import isEmpty from "../../utils/isempty.utils";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Dashboard",
    icon: "eva:dashboard-fill",
  },
  {
    label: "Profile",
    icon: "eva:person-fill",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState<any>(null);

  const user = useSelector((state: any) => state.userReducer);
  const [name, setName] = useState<string>("test gggg");

  useEffect(() => {
    if (!isEmpty(user)) {
      setName(user.username);
    } else {
      setName("John Doe");
    }
  }, [user]);
  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          marginLeft: 3,
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar {...MuiUserUtils.stringAvatar(name)} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            John Doe
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            john.doe.@mail.fr
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <Link
              key={option.label}
              to={`/${option.label.toLowerCase()}`}
              style={{ textDecoration: "none", width: "100%", color: "#000000" }}
            >
              <MenuItem onClick={handleClose}>{option.label}</MenuItem>
            </Link>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />
        <Link to={"/logout"} style={{ textDecoration: "none", width: "100%", color: "#000000" }}>
          <MenuItem onClick={handleClose} sx={{ m: 1 }}>
            Logout
          </MenuItem>
        </Link>
      </Popover>
    </>
  );
}
