import ErrorPage from "./views/ErrorPage";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Login from "./views/login/Login";
import Dashboard from "./views/dashboard/Dashboard";
import Logout from "./views/Logout";
import Analytics from "./views/analytics/Analytics";
import GlobalStructure from "./layouts/GlobalStructure";
import CardDesign from "./views/card-design/CardDesign";
import Chat from "./views/chat/Chat";
import Enterprise from "./views/enterprise/Enterprise";
import FileManager from "./views/file-manager/FileManager";
import Profile from "./views/profil/Profile";
import Settings from "./views/settings/Settings";
import Shop from "./views/shop/Shop";
import ListUsers from "./views/user/list-users/ListUsers";
import CardUsers from "./views/user/card-users/CardUsers";
import RedirectCount from "./views/redirect-count/RedirectCount";
import CreateCompany from "./views/login/CreateCompany";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getLANG } from "./actions/API/langue.action";

const App = () => {
  const dispatch: any = useDispatch();
  useEffect(() => {
    dispatch(getLANG("FR"));
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="*" element={<ErrorPage />} />
        <Route path="/" element={<Login />} />
        <Route path="/create-company" element={<CreateCompany />} />
        <Route path="/redirect/:id/:url" element={<RedirectCount />} />
        <Route
          path="/dashboard"
          element={
            <GlobalStructure>
              {" "}
              <Dashboard />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/analytics"
          element={
            <GlobalStructure>
              {" "}
              <Analytics />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/card-design"
          element={
            <GlobalStructure>
              {" "}
              <CardDesign />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/chat"
          element={
            <GlobalStructure>
              {" "}
              <Chat />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/enterprise"
          element={
            <GlobalStructure>
              {" "}
              <Enterprise />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/card-users"
          element={
            <GlobalStructure>
              {" "}
              <CardUsers />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/list-users"
          element={
            <GlobalStructure>
              {" "}
              <ListUsers />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/file-manager"
          element={
            <GlobalStructure>
              {" "}
              <FileManager />{" "}
            </GlobalStructure>
          }
        />
        <Route
          path="/profile"
          element={
            <GlobalStructure>
              {" "}
              <Profile />{" "}
            </GlobalStructure>
          }
        />

        <Route
          path="/shop"
          element={
            <GlobalStructure>
              {" "}
              <Shop />{" "}
            </GlobalStructure>
          }
        />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </Router>
  );
};

export default App;
