import CompagnyService from "../../services/api/compagny.service";
export const GET_COMPANY_ME = "GET_COMPANY_ME";


export const getMeCompagny = (token) => {
  return (dispatch) => {
    return  CompagnyService.getCompanyByOwner(token).then(r => {
      if(r.status === 200){
        dispatch({ type: GET_COMPANY_ME, payload: r.data });
      }else{
        dispatch({ type: GET_COMPANY_ME, payload: [] });
      }
    }) .catch((err) => console.log(""));
  };
};
