import http from "../../http-common";

const getAllInvoice = (token: string) => {
  return http.get<any>("/invoices/public/get-all-my-invoices/", { headers: { Authorization: "Bearer " + token } });
};

const InvoiceService = {
  getAllInvoice,
};

export default InvoiceService;
