import MediaService from "../../services/api/media.service";
export const GET_COMPANY_MEDIA = "GET_COMPANY_MEDIA";


export const getMeCompagnyMedia = (token) => {
  return (dispatch) => {
    return  MediaService.getAllMedia(token).then(r => {
      if(r.status === 200){
        dispatch({ type: GET_COMPANY_MEDIA, payload: r.data });
      }else{
        dispatch({ type: GET_COMPANY_MEDIA, payload: [] });
      }
    }) .catch((err) => console.log(""));
  };
};
