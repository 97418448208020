import { Card, CardHeader, Box } from "@mui/material";
import { WidgetChartLinearType } from "../../models/component/chart/widgetchartlinear.model";
import { Chart, ChartData, registerables } from "chart.js";
import { useEffect, useRef } from "react";
import { Line, Pie } from "react-chartjs-2";

Chart.register(...registerables);

const WidgetChartLinear = (props: WidgetChartLinearType) => {
  const { chartData, chartLabels, subheader, title, width, widthBox } = props;

  return (
    <Card sx={{ marginRight: 2, padding: 3, width: widthBox }}>
      <CardHeader title={title} subheader={subheader} />
      <Box>
        <Line
          data={{
            labels: chartLabels,
            datasets: [
              {
                data: chartData,
                borderColor: ["rgb(34, 10, 110)"],
                fill: false,
                cubicInterpolationMode: "monotone",
                tension: 0.4,
              },
            ],
          }}
          width={width}
          height={300}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                },
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: "Value",
                },
              },
            },
          }}
        />
      </Box>
    </Card>
  );
};

export default WidgetChartLinear;
