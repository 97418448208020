import {GET_COMPANY_ME} from "../../actions/API/company.actions";


const initialState = {};

export default function companyReducer(state = initialState, action) {
  switch (action.type) {
  case GET_COMPANY_ME:
    return action.payload;
  default:
    return state;
  }
}
