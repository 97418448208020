import React, { useState } from "react";
import StepperCardDesign from "../../components/stepper/Stepper";
import { useSelector } from "react-redux";

const CardDesign = () => {
  const [dataColor, setDataColor] = useState("#fff");
  const [alignment, setAlignment] = useState("left");
  const lang = useSelector((state: any) => state.langReducer);
  return (
    <div className="view--card-design">
      <div className="title-card-design">
        {lang === "FR" ? "Créer un design pour vos cartes" : "Create Card Design"}
      </div>
      <div className="container-card-design">
        <div className="container-image-dededed">
          <img src="/assets/images/card-design.jpg" alt="" />
          <div
            className="card-exemple"
            style={
              alignment === "right"
                ? { backgroundColor: dataColor, alignItems: "flex-end" }
                : { backgroundColor: dataColor, alignItems: alignment }
            }
          >
            <div className="element">Arnaud</div>
            <div className="element">David-Henri</div>
            <div className="element">dharaud77@hotmail.fr</div>
            <div className="element">TestTestTestTestTest</div>
          </div>
        </div>
        <div className="right-part">
          <StepperCardDesign
            alignment={alignment}
            setAlignment={setAlignment}
            dataColor={dataColor}
            setDataColor={setDataColor}
          />
        </div>
      </div>
    </div>
  );
};

export default CardDesign;
