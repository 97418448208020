import NotificationService from "../../services/api/notification.service";
export const GET_NOTIFICATIONS_ROLE = "GET_NOTIFICATIONS_ROLE";

export const getNotificationsWithRole = (token) => {
  return (dispatch) => {
    return  NotificationService.getUnreadNotificationsForUser(token).then(r => {
      if(r.status === 200){
        dispatch({ type: GET_NOTIFICATIONS_ROLE, payload: r.data });
      }else{
        dispatch({ type: GET_NOTIFICATIONS_ROLE, payload: [] });
      }
    }) .catch((err) => console.log(""));
  };
};

