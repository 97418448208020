import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import firstStep from "./firststep/FirstStep";
import SecondStep from "./secondstep/SecondStep";
import FinalStep from "./finalstep/FinalStep";
import CompanyService from "../../services/api/compagny.service";
import { Alert, Snackbar } from "@mui/material";
import { CreateCardPresetRequest } from "../../models/entities/compagny/request/create-card-preset.request";
import { AlignmentCardEnum } from "../../models/enum/alignment-card.enum";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../utils/isempty.utils";
import AuthService from "../../services/api/auth.service";

interface Stepper {
  dataColor: string;
  setDataColor: React.Dispatch<React.SetStateAction<string>>;
  alignment: string;
  setAlignment: React.Dispatch<React.SetStateAction<string>>;
}
const StepperCardDesign = (props: Stepper) => {
  const { dataColor, setDataColor, alignment, setAlignment } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [openFailed, setOpenFailed] = useState(false);
  const [openCreated, setOpenCreated] = useState(false);
  const [message, setMessage] = useState("");
  const company = useSelector((state: any) => state.company);
  const [token, setToken] = useState<any>("");
  const [companyid, setCompanyId] = useState<any>("");
  const lang = useSelector((state: any) => state.langReducer);
  const dispatch: any = useDispatch();

  useEffect(() => {
    setDataColor(dataColor);
  }, [dataColor]);

  const steps = [
    {
      label: lang === "FR" ? "Selectionné votre couleur" : "Select Colors card design",
      description: firstStep(isActive, setIsActive, setDataColor),
    },
    {
      label: lang === "FR" ? "Selectionné votre position" : "Select position card design",
      description: SecondStep(alignment, setAlignment),
    },
    {
      label: lang === "FR" ? "Validé votre cartes" : "Check and created card",
      description: FinalStep(),
    },
  ];

  const handleNext = (index: number) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (index === steps.length - 1) {
      createCardPreset();
    }
  };

  const returnAlignToVariable = () => {
    if (alignment === "left") {
      return AlignmentCardEnum.LEFT;
    } else if (alignment === "center") {
      return AlignmentCardEnum.CENTER;
    } else if (alignment === "right") {
      return AlignmentCardEnum.RIGHT;
    } else {
      return AlignmentCardEnum.LEFT;
    }
  };

  const createCardPreset = () => {
    const dataCreate: CreateCardPresetRequest = {
      alignment: returnAlignToVariable(),
      backgroundColor: dataColor,
      companyId: companyid,
    };
    requestCreateApi(dataCreate, token);
  };

  const requestCreateApi = (data: CreateCardPresetRequest, token: string) => {
    CompanyService.createCardPreset(data, token)
      .then((r) => {
        if (r.status === 201) {
          setOpenCreated(true);
          setMessage("Card crée");
          setTimeout(() => {
            setOpenCreated(false);
          }, 4000);
        }
      })
      .catch((err) => {
        setOpenFailed(true);
        setMessage("User non crée");
        setTimeout(() => {
          setOpenFailed(false);
        }, 4000);
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setAlignment("flex-start");
    setDataColor("#FFF");
  };

  const handleCloseSnackbar = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };

  useEffect(() => {
    if (!isEmpty(AuthService.getUserConnected())) {
      setToken(AuthService.getUserConnected());
      if (!isEmpty(AuthService.getCompanyConnect())) {
        setCompanyId(AuthService.getCompanyConnect());
      }
    }
  }, [token, companyid]);

  return (
    <>
      <Box>
        <Stepper activeStep={activeStep} orientation="vertical" sx={{ color: "#22286F" }}>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                sx={{ color: "#22286F" }}
                optional={
                  index === 2 ? (
                    <Typography variant="caption">{lang === "FR" ? "Dernière étape" : "Last step"}</Typography>
                  ) : null
                }
              >
                <div style={{ color: "#22286F" }}>{step.label}</div>
              </StepLabel>
              <StepContent>
                <>{step.description}</>
                <Box sx={{ mb: 2 }}>
                  <div>
                    {dataColor === "" && (
                      <Button
                        variant="contained"
                        onClick={() => handleNext(index)}
                        disabled
                        sx={{ mt: 1, mr: 1, backgroundColor: "#d2d2d2" }}
                      >
                        {index === steps.length - 1 ? (lang === "FR" ? "Finir" : "Finish") : "Continue"}
                      </Button>
                    )}
                    {dataColor != "" && (
                      <Button
                        variant="contained"
                        onClick={() => handleNext(index)}
                        sx={{
                          mt: 1,
                          mr: 1,
                          backgroundColor: dataColor != "" ? "#22286F" : "#d1e7dd",
                          "&:hover": { backgroundColor: "#22286F" },
                        }}
                      >
                        {index === steps.length - 1 ? (lang === "FR" ? "Finir" : "Finish") : "Continue"}
                      </Button>
                    )}
                    <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      {lang === "FR" ? "Retour" : "Back"}
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3, background: "#22286F" }}>
            <Typography sx={{ color: "#FFF" }}>
              {lang === "FR" ? "Tous les etapes sont fini" : "All steps completed"}
            </Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1, backgroundColor: "#FFF" }}>
              {lang === "FR" ? "Recommencé" : "Reset"}
            </Button>
          </Paper>
        )}
      </Box>
      <Snackbar open={openCreated} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default StepperCardDesign;
