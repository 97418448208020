import { useEffect, useState } from "react";
import CardUser from "../../../components/card_user/CardUser";
import isEmpty from "../../../utils/isempty.utils";
import AuthService from "../../../services/api/auth.service";
import CompanyService from "../../../services/api/compagny.service";
import { CompanyEmployeeResponse } from "../../../models/entities/compagny/response/company-employee.response";
import { useSelector } from "react-redux";
import { CardModel } from "../../../models/entities/card/response/card.response";
import {RoleProfileEnum} from "../../../models/enum/role-profile.enum";

const CardUsers = () => {
  const array:Array<CompanyEmployeeResponse> = [{
    id: "d&1222",
    roles: [],
    profile: {
      avatarMedia: "string",
      bannerMedia: "string",
      createdAt: "string",
      deletedAt: "string",
      id: "string",
      occupations: [],
      personalCards: [],
      roleProfile: RoleProfileEnum.COMPANY,
      savedCard: [],
      updatedAt: "string",
      user: {
        id: "string",
        roles: [],
        username: "string",
        mail: "string",
        password: "string",
        profiles: [],
        createdAt: "string",
      },
    },
    company: {
      id: "string",
      name: "string",
      description: "string",
      address: [],
      website: "string",
      phone: "string",
      email: "string",
      employees: [],
      occupations: [],
      ownerProfile: {
        id: "string",
        usernameProfile: "string",
        roleProfile: "string",
        user: {
          id: "string",
          roles: [],
          username: "string",
          mail: "string",
          password: "string",
          profiles: [],
          createdAt: "string",
        }
      },
      createdAt: "string",
      updatedAt: "string",
      deletedAt: "string",
    },
    createdAt: "20/09/2021",
    updatedAt: "20/09/2021",
    deletedAt: "20/09/2021",
  },{
    id: "d&1422",
    roles: [],
    profile: {
      avatarMedia: "string",
      bannerMedia: "string",
      createdAt: "string",
      deletedAt: "string",
      id: "string",
      occupations: [],
      personalCards: [],
      roleProfile: RoleProfileEnum.COMPANY,
      savedCard: [],
      updatedAt: "string",
      user: {
        id: "string",
        roles: [],
        username: "string",
        mail: "string",
        password: "string",
        profiles: [],
        createdAt: "string",
      },
    },
    company: {
      id: "string",
      name: "string",
      description: "string",
      address: [],
      website: "string",
      phone: "string",
      email: "string",
      employees: [],
      occupations: [],
      ownerProfile: {
        id: "string",
        usernameProfile: "string",
        roleProfile: "string",
        user: {
          id: "string",
          roles: [],
          username: "string",
          mail: "string",
          password: "string",
          profiles: [],
          createdAt: "string",
        }
      },
      createdAt: "string",
      updatedAt: "string",
      deletedAt: "string",
    },
    createdAt: "20/09/2021",
    updatedAt: "20/09/2021",
    deletedAt: "20/09/2021",
  },{
    id: "d&1322",
    roles: [],
    profile: {
      avatarMedia: "string",
      bannerMedia: "string",
      createdAt: "string",
      deletedAt: "string",
      id: "string",
      occupations: [],
      personalCards: [],
      roleProfile: RoleProfileEnum.COMPANY,
      savedCard: [],
      updatedAt: "string",
      user: {
        id: "string",
        roles: [],
        username: "string",
        mail: "string",
        password: "string",
        profiles: [],
        createdAt: "string",
      },
    },
    company: {
      id: "string",
      name: "string",
      description: "string",
      address: [],
      website: "string",
      phone: "string",
      email: "string",
      employees: [],
      occupations: [],
      ownerProfile: {
        id: "string",
        usernameProfile: "string",
        roleProfile: "string",
        user: {
          id: "string",
          roles: [],
          username: "string",
          mail: "string",
          password: "string",
          profiles: [],
          createdAt: "string",
        }
      },
      createdAt: "string",
      updatedAt: "string",
      deletedAt: "string",
    },
    createdAt: "20/09/2021",
    updatedAt: "20/09/2021",
    deletedAt: "20/09/2021",
  },{
    id: "d&1222",
    roles: [],
    profile: {
      avatarMedia: "string",
      bannerMedia: "string",
      createdAt: "string",
      deletedAt: "string",
      id: "string",
      occupations: [],
      personalCards: [],
      roleProfile: RoleProfileEnum.COMPANY,
      savedCard: [],
      updatedAt: "string",
      user: {
        id: "string",
        roles: [],
        username: "string",
        mail: "string",
        password: "string",
        profiles: [],
        createdAt: "string",
      },
    },
    company: {
      id: "string",
      name: "string",
      description: "string",
      address: [],
      website: "string",
      phone: "string",
      email: "string",
      employees: [],
      occupations: [],
      ownerProfile: {
        id: "string",
        usernameProfile: "string",
        roleProfile: "string",
        user: {
          id: "string",
          roles: [],
          username: "string",
          mail: "string",
          password: "string",
          profiles: [],
          createdAt: "string",
        }
      },
      createdAt: "string",
      updatedAt: "string",
      deletedAt: "string",
    },
    createdAt: "20/09/2021",
    updatedAt: "20/09/2021",
    deletedAt: "20/09/2021",
  },{
    id: "d&1422",
    roles: [],
    profile: {
      avatarMedia: "string",
      bannerMedia: "string",
      createdAt: "string",
      deletedAt: "string",
      id: "string",
      occupations: [],
      personalCards: [],
      roleProfile: RoleProfileEnum.COMPANY,
      savedCard: [],
      updatedAt: "string",
      user: {
        id: "string",
        roles: [],
        username: "string",
        mail: "string",
        password: "string",
        profiles: [],
        createdAt: "string",
      },
    },
    company: {
      id: "string",
      name: "string",
      description: "string",
      address: [],
      website: "string",
      phone: "string",
      email: "string",
      employees: [],
      occupations: [],
      ownerProfile: {
        id: "string",
        usernameProfile: "string",
        roleProfile: "string",
        user: {
          id: "string",
          roles: [],
          username: "string",
          mail: "string",
          password: "string",
          profiles: [],
          createdAt: "string",
        }
      },
      createdAt: "string",
      updatedAt: "string",
      deletedAt: "string",
    },
    createdAt: "20/09/2021",
    updatedAt: "20/09/2021",
    deletedAt: "20/09/2021",
  },{
    id: "d&1322",
    roles: [],
    profile: {
      avatarMedia: "string",
      bannerMedia: "string",
      createdAt: "string",
      deletedAt: "string",
      id: "string",
      occupations: [],
      personalCards: [],
      roleProfile: RoleProfileEnum.COMPANY,
      savedCard: [],
      updatedAt: "string",
      user: {
        id: "string",
        roles: [],
        username: "string",
        mail: "string",
        password: "string",
        profiles: [],
        createdAt: "string",
      },
    },
    company: {
      id: "string",
      name: "string",
      description: "string",
      address: [],
      website: "string",
      phone: "string",
      email: "string",
      employees: [],
      occupations: [],
      ownerProfile: {
        id: "string",
        usernameProfile: "string",
        roleProfile: "string",
        user: {
          id: "string",
          roles: [],
          username: "string",
          mail: "string",
          password: "string",
          profiles: [],
          createdAt: "string",
        }
      },
      createdAt: "string",
      updatedAt: "string",
      deletedAt: "string",
    },
    createdAt: "20/09/2021",
    updatedAt: "20/09/2021",
    deletedAt: "20/09/2021",
  }]
  const [cardUsers, setCardUsers] = useState<CompanyEmployeeResponse[]>(array);
  const [token, setToken] = useState<any>("");
  const lang = useSelector((state: any) => state.langReducer);

  useEffect(() => {
    if (!isEmpty(AuthService.getUserConnected())) {
      setToken(AuthService.getUserConnected());
      CompanyService.getEmployeebyCompanyByOwner(token)
        .then((r) => {
          setCardUsers(r.data);
        })
        .catch((reason) => console.log(reason));
    }
  }, [token]);

  return (
    <div className="view--card-users">
      <div className="title-users">{lang === "FR" ? "Cartes Salariés" : "Cards Employees"}</div>
      <div className="container-card-users">
        {cardUsers &&
          cardUsers.map((value, index) => {
            let cardShare = 30;
            let cardGetting = 40;
            let viewCard = 100;
            value.profile.personalCards?.forEach((card: CardModel) => {
              cardShare += (card.connectedCardTwo?.length ?? 0) + (card.connectedCardOne?.length ?? 0);
            });

            value.profile.personalCards?.forEach((card: CardModel) => {
              cardGetting += card.profilesWhoSavedCard.length;
            });

            value.profile.personalCards?.forEach((card: CardModel) => {
              viewCard += card.numberOfShares;
            });

            return (
              <CardUser
                key={index}
                imageP={""}
                name={"John Doe"}
                job={"John.Doe@mail.fr"}
                cards={[]}
                cardShares={cardShare}
                cardGetting={cardGetting}
                viewCard={viewCard}
              />
            );
          })}
      </div>
    </div>
  );
};

export default CardUsers;
