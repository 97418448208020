import { alpha, styled, Theme } from "@mui/material/styles";
import { Card, SvgIconTypeMap, SxProps, Typography } from "@mui/material";
import { fShortenNumber } from "../../utils/formatnumber.utils";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ReactElement } from "react";

// ----------------------------------------------------------------------

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

interface WidgetCardInformationType {
  color: string;
  icon: ReactElement<any, any>;
  title: string;
  total: number;
  sx: SxProps<Theme> | undefined;
  bgcolor: string;
}

const WidgetCardInformation = (props: WidgetCardInformationType) => {
  const { title, total, icon, color, bgcolor, sx, ...other } = props;
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        marginRight: 2,
        width: "40%",
        textAlign: "center",
        color: color,
        bgcolor: bgcolor,
        ...sx,
      }}
      {...other}
    >
      <StyledIcon
        sx={{
          color: "#fff",
          backgroundImage: (theme) => `linear-gradient(135deg, ${alpha("#000", 0)} 0%, ${alpha("#fff", 0.24)} 100%)`,
        }}
      >
        {icon}
      </StyledIcon>

      <Typography variant="h3">{total}</Typography>

      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
};

export default WidgetCardInformation;
