import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { RoleGroupMembershipEnum } from "../../models/enum/role-group-membership.enum";
import { UserModel } from "../../models/entities/user/user.response.model";
import { GroupMembershipModel } from "../../models/entities/groupe/response/group-membership.response";
import React, { useEffect } from "react";
import { GroupResponse } from "../../models/entities/groupe/response/group.response";
import SettingsIcon from "@mui/icons-material/Settings";

interface MemberComponentProps {
  index: number;
  group: GroupResponse;
  member: GroupMembershipModel;
  actualUser: UserModel;
  isCurrentUser: boolean;
  isConnected: boolean;
  isAdmin: boolean;
  isMember: boolean;
  isOwner: boolean;
  isActualUserAdmin: boolean;
  isActualUserMember: boolean;
  isActualUserOwner: boolean;
  handleRemoveFromGroup: (memberId: string) => void;
  handleGiveAdminRights: (memberId: string) => void;
  handleRemoveAdminRights: (memberId: string) => void;
  handleDeleteGroup: (groupId: string) => void;
  handleLeaveGroup: (memberId: string) => void;
}

const MemberComponent: React.FC<MemberComponentProps> = ({
  index,
  group,
  member,
  actualUser,
  isCurrentUser,
  isConnected,
  isActualUserAdmin,
  isActualUserMember,
  isAdmin,
  isMember,
  isOwner,
  isActualUserOwner,
  handleRemoveFromGroup,
  handleGiveAdminRights,
  handleRemoveAdminRights,
  handleDeleteGroup,
  handleLeaveGroup,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const styles = {
    background: "none",
    color: "#000",
    ":hover": {
      background: "none",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        marginTop: "60px",
      }}
    >
      <div style={{ fontSize: "16px" }}>{member.card?.owner.usernameProfile}</div>
      {!isCurrentUser && (
        <Tooltip title={isConnected ? "Connected" : "Not Connected"}>
          <div
            style={{
              height: "10px",
              width: "10px",
              backgroundColor: isConnected ? "green" : "red",
              borderRadius: "50%",
            }}
          />
        </Tooltip>
      )}
      {(!isActualUserMember || isCurrentUser) && (
        <>
          <Button style={styles} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <SettingsIcon />
          </Button>
          {/* Your existing code... */}
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            {isActualUserOwner && !isCurrentUser && (
              <>
                <MenuItem onClick={() => handleRemoveFromGroup(member.card?.id ?? "error")}>Remove from group</MenuItem>
              </>
            )}
            {isActualUserOwner && isCurrentUser && (
              <>
                <MenuItem onClick={() => handleDeleteGroup(group.id ?? "error")}>Delete group</MenuItem>
              </>
            )}
            {!isActualUserOwner && isCurrentUser && (
              <>
                <MenuItem onClick={() => handleLeaveGroup(group.id ?? "error")}>Leave group</MenuItem>
              </>
            )}
          </Menu>
        </>
      )}
    </div>
  );
};
export default MemberComponent;
