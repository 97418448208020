import UserService from "../../services/api/user.service";
import {DispatchProp} from "react-redux";

export const GET_ME = "GET_ME";


export const getMe = (token) => {
  return (dispatch) => {
    return  UserService.getMe(token).then(r => {
      if(r.status === 200){
        dispatch({ type: GET_ME, payload: r.data });
      }else{
        dispatch({ type: GET_ME, payload: [] });
      }
    }) .catch((err) => console.log(""));
  };
};
