import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WidgetCardInformation from "../../components/widget_card_information/WidgetCardInformation";
import { useEffect, useState } from "react";
import isEmpty from "../../utils/isempty.utils";
import AuthService from "../../services/api/auth.service";
import StatsService from "../../services/api/stats.service";
import { useSelector } from "react-redux";
import WidgetChartBar from "../../components/WidgetChartBar/WidgetChartBar";

const Dashboard = () => {
  /*const [numberOfCardShare, setNumberOfCardShare] = useState(0);
  const [numberOfCardGet, setNumberOfCardGet] = useState(0);
  const [numberOfEmployee, setNumberOfEmployee] = useState(0);
  const [numberOfCard, setNumberOfCard] = useState(0);*/
  const [token, setToken] = useState<any>("");
  const [companyId, setCompanyId] = useState<any>();
  const lang = useSelector((state: any) => state.langReducer);

  /*const [reciveidCard, setRecievedCard] = useState<Array<ChartResponse>>([]);
    const [forwardCard, setForwardCard] = useState<Array<ChartResponse>>([]);
    const [viewCard, setViewCard] = useState<Array<ChartResponse>>([]);
     function extractYValues(chartData: ChartResponse[]): number[] {
      return chartData.map((data) => data.y);
    }

    function extractXValues(chartData: ChartResponse[]): string[] {
      return chartData.map((data) => data.x);
    }*/

  useEffect(() => {
    if (!isEmpty(AuthService.getUserConnected())) {
      setToken(AuthService.getUserConnected());
      if (!isEmpty(AuthService.getCompanyConnect())) {
        setCompanyId(AuthService.getCompanyConnect());
      }
    }
  }, [token, companyId]);

  useEffect(() => {
    if (token != "" && companyId != "") {
      StatsService.getAllemployeeCountByEntreprise(companyId, token)
        .then((r) => {
          if (r.status === 200) {
            //setNumberOfEmployee(r.data);
          }
        })
        .catch(() => console.log(""));
      StatsService.getAllCardCountByCompagny(companyId, token)
        .then((r) => {
          if (r.status === 200) {
            //setNumberOfCard(r.data);
          }
        })
        .catch(() => console.log(""));

      StatsService.getAllCardViewCountByEntreprise(companyId, token)
        .then((r) => {
          if (r.status === 200) {
            //setNumberOfCardGet(r.data);
          }
        })
        .catch(() => console.log(""));

      StatsService.getAllCardForwardCountByEntreprise(companyId, token)
        .then((r) => {
          if (r.status === 200) {
            //setNumberOfCardShare(r.data);
          }
        })
        .catch(() => console.log(""));

      StatsService.getAllEmployeePersonalCardView(companyId, token)
        .then((r) => {
          if (r.status === 200) {
            //setViewCard(r.data);
          }
        })
        .catch(() => console.log(""));
      StatsService.getCompanyForwardCard(companyId, token)
        .then((r) => {
          if (r.status === 200) {
            //setForwardCard(r.data);
          }
        })
        .catch(() => console.log(""));
      StatsService.getCompanyReceivedCard(companyId, token)
        .then((r) => {
          if (r.status === 200) {
            //setRecievedCard(r.data);
          }
        })
        .catch(() => console.log(""));
    }
  }, [companyId, token]);

  return (
    <div className="view-dashboard">
      <div className="title-header"> {lang === "FR" ? "Bonjour, Bienvenue" : "Hi, Welcome Back"}</div>
      <div className="container-dashboard">
        <WidgetCardInformation
          color={"#FFF"}
          icon={<SimCardDownloadIcon />}
          title={lang === "FR" ? "Carte distribué" : "Cards Shared"}
          total={40}
          sx={{}}
          bgcolor={"#f04337"}
        />
        <WidgetCardInformation
          color={"#FFF"}
          icon={<CreditCardIcon />}
          title={lang === "FR" ? "Carte Récupérer" : "Recover card"}
          total={20}
          sx={{}}
          bgcolor={"#11b981"}
        />
        <WidgetCardInformation
          color={"#FFF"}
          icon={<AccountCircleIcon />}
          title={lang === "FR" ? "Salariée" : "Employee"}
          total={40}
          sx={{}}
          bgcolor={"#f79007"}
        />
        <WidgetCardInformation
          color={"#FFF"}
          icon={<SimCardDownloadIcon />}
          title={lang === "FR" ? "Cartes Total" : "Total Card"}
          total={60}
          sx={{}}
          bgcolor={"#6366f1"}
        />
      </div>
      <div className="container-dashboard marginTop">
        <WidgetChartBar
          chartData={[50,200,10,600,800,90,50,500,900,1000]}
          chartLabels={["1/02/2023","2/02/2023","3/02/2023","4/02/2023","5/02/2023","6/02/2023","7/02/2023","8/02/2023","9/02/2023","10/02/2023"]}
          widthBox={"100%"}
          width={1000}
          title={
            lang === "FR"
              ? "Nombre de Cartes social network partager par Date"
              : "Number of Social Media Cards Shared by Date"
          }
          subheader={lang === "FR" ? "Cartes Réseaux Social/Date" : "Social Media Cards Shared / Date"}
        />
      </div>
    </div>
  );
};

export default Dashboard;
