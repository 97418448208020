import  {ChangeEvent, useEffect, useState} from "react";

import {
  Alert,
  Button,
  Card,
  Checkbox,
  Container,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Popover,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import {useForm} from "react-hook-form";
import {applySortFilterOther, getComparator} from "../../../utils/array-user-list";
import {UserRoleEnum} from "../../../models/enum/user-role.enum";
import AuthService from "../../../services/api/auth.service";
import ArrayListToolbar from "../../../components/array_manager/ArrayListToolbar";
import Scrollbar from "../../../components/mui/scrollbar/Scrollbar";
import ArrayListHead from "../../../components/array_manager/ArrayListHead";
import {ProfileCompgnyResponse} from "../../../models/entities/profile/response/profile-compgny.response";
import {RoleProfileEnum} from "../../../models/enum/role-profile.enum";
import ProfileService from "../../../services/api/profile.service";
import CompanyService from "../../../services/api/compagny.service";
import {CreateUserForCompanyRequest} from "../../../models/entities/compagny/request/create-user-for-company.request";
import {UpdateProfileRequest} from "../../../models/entities/profile/request/update-profile.request";
import {RoleCompanyEmployeeEnum} from "../../../models/enum/role-company-employee.enum";
import {useSelector} from "react-redux";
import {CompanyEmployeeResponse} from "../../../models/entities/compagny/response/company-employee.response";
import isEmpty from "../../../utils/isempty.utils";
import {RemoveCompanyEmployeeRequest} from "../../../models/entities/compagny/request/remove-company-employee.request";

const MockUser: Array<CompanyEmployeeResponse> = [
  {
    id: "12132131AA1344",
    roles: [RoleCompanyEmployeeEnum.ADMIN],
    profile: {
      avatarMedia: "",
      bannerMedia: "",
      createdAt: "20/09/2023",
      deletedAt: "",
      id: "",
      occupations: [],
      personalCards: [],
      roleProfile: RoleProfileEnum.PREMIUM,
      savedCard: [],
      updatedAt: "20/10/2023",
      user: {
        id: "",
        roles: [],
        username: "John Doe",
        mail: "",
        profiles: [],
        password: "",
        createdAt: "",
      },
    },
    company: {
      id: "",
      name: "",
      description: "",
      address: [],
      website: "",
      phone: "",
      email: "",
      employees: [],
      occupations: [],
      ownerProfile: {
        id: "",
        usernameProfile: "",
        roleProfile: "",
      },
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
    },
    createdAt: "20/09/2023",
    updatedAt: "20/10/2023",
  },
  {
    id: "12132131A1321",
    roles: [RoleCompanyEmployeeEnum.ADMIN],
    profile: {
      avatarMedia: "",
      bannerMedia: "",
      createdAt: "20/09/2023",
      deletedAt: "",
      id: "",
      occupations: [],
      personalCards: [],
      roleProfile: RoleProfileEnum.PREMIUM,
      savedCard: [],
      updatedAt: "20/10/2023",
      user: {
        id: "",
        roles: [],
        username: "John Doe",
        mail: "",
        profiles: [],
        password: "",
        createdAt: "",
      },
    },
    company: {
      id: "",
      name: "",
      description: "",
      address: [],
      website: "",
      phone: "",
      email: "",
      employees: [],
      occupations: [],
      ownerProfile: {
        id: "",
        usernameProfile: "",
        roleProfile: "",
      },
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
    },
    createdAt: "20/09/2023",
    updatedAt: "20/10/2023",
  },
  {
    id: "12132131AA22",
    roles: [RoleCompanyEmployeeEnum.ADMIN],
    profile: {
      avatarMedia: "",
      bannerMedia: "",
      createdAt: "20/09/2023",
      deletedAt: "",
      id: "",
      occupations: [],
      personalCards: [],
      roleProfile: RoleProfileEnum.PREMIUM,
      savedCard: [],
      updatedAt: "20/10/2023",
      user: {
        id: "",
        roles: [],
        username: "John Doe",
        mail: "",
        profiles: [],
        password: "",
        createdAt: "",
      },
    },
    company: {
      id: "",
      name: "",
      description: "",
      address: [],
      website: "",
      phone: "",
      email: "",
      employees: [],
      occupations: [],
      ownerProfile: {
        id: "",
        usernameProfile: "",
        roleProfile: "",
      },
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
    },
    createdAt: "20/09/2023",
    updatedAt: "20/10/2023",
  },
  {
    id: "12132131AA2211",
    roles: [RoleCompanyEmployeeEnum.ADMIN],
    profile: {
      avatarMedia: "",
      bannerMedia: "",
      createdAt: "20/09/2023",
      deletedAt: "",
      id: "",
      occupations: [],
      personalCards: [],
      roleProfile: RoleProfileEnum.PREMIUM,
      savedCard: [],
      updatedAt: "20/10/2023",
      user: {
        id: "",
        roles: [],
        username: "John Doe",
        mail: "",
        profiles: [],
        password: "",
        createdAt: "",
      },
    },
    company: {
      id: "",
      name: "",
      description: "",
      address: [],
      website: "",
      phone: "",
      email: "",
      employees: [],
      occupations: [],
      ownerProfile: {
        id: "",
        usernameProfile: "",
        roleProfile: "",
      },
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
    },
    createdAt: "20/09/2023",
    updatedAt: "20/10/2023",
  },
  {
    id: "12132131AA22",
    roles: [RoleCompanyEmployeeEnum.ADMIN],
    profile: {
      avatarMedia: "",
      bannerMedia: "",
      createdAt: "20/09/2023",
      deletedAt: "",
      id: "",
      occupations: [],
      personalCards: [],
      roleProfile: RoleProfileEnum.PREMIUM,
      savedCard: [],
      updatedAt: "20/10/2023",
      user: {
        id: "",
        roles: [],
        username: "John Doe",
        mail: "",
        profiles: [],
        password: "",
        createdAt: "",
      },
    },
    company: {
      id: "",
      name: "",
      description: "",
      address: [],
      website: "",
      phone: "",
      email: "",
      employees: [],
      occupations: [],
      ownerProfile: {
        id: "",
        usernameProfile: "",
        roleProfile: "",
      },
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
    },
    createdAt: "20/09/2023",
    updatedAt: "20/10/2023",
  },
  {
    id: "121333131AA13",
    roles: [RoleCompanyEmployeeEnum.ADMIN],
    profile: {
      avatarMedia: "",
      bannerMedia: "",
      createdAt: "20/09/2023",
      deletedAt: "",
      id: "",
      occupations: [],
      personalCards: [],
      roleProfile: RoleProfileEnum.PREMIUM,
      savedCard: [],
      updatedAt: "20/10/2023",
      user: {
        id: "",
        roles: [],
        username: "John Doe",
        mail: "",
        profiles: [],
        password: "",
        createdAt: "",
      },
    },
    company: {
      id: "",
      name: "",
      description: "",
      address: [],
      website: "",
      phone: "",
      email: "",
      employees: [],
      occupations: [],
      ownerProfile: {
        id: "",
        usernameProfile: "",
        roleProfile: "",
      },
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
    },
    createdAt: "20/09/2023",
    updatedAt: "20/10/2023",
  },
  {
    id: "12132131AA13",
    roles: [RoleCompanyEmployeeEnum.ADMIN],
    profile: {
      avatarMedia: "",
      bannerMedia: "",
      createdAt: "20/09/2023",
      deletedAt: "",
      id: "",
      occupations: [],
      personalCards: [],
      roleProfile: RoleProfileEnum.PREMIUM,
      savedCard: [],
      updatedAt: "20/10/2023",
      user: {
        id: "",
        roles: [],
        username: "John Doe",
        mail: "",
        profiles: [],
        password: "",
        createdAt: "",
      },
    },
    company: {
      id: "",
      name: "",
      description: "",
      address: [],
      website: "",
      phone: "",
      email: "",
      employees: [],
      occupations: [],
      ownerProfile: {
        id: "",
        usernameProfile: "",
        roleProfile: "",
      },
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
    },
    createdAt: "20/09/2023",
    updatedAt: "20/10/2023",
  },
];

const profile: ProfileCompgnyResponse = {
  avatarMedia: "",
  bannerMedia: "",
  createdAt: "",
  deletedAt: "",
  id: "",
  occupations: [],
  personalCards: [],
  roleProfile: RoleProfileEnum.PREMIUM,
  savedCard: [],
  updatedAt: "",
  user: {
    id: "",
    roles: [],
    username: "",
    mail: "",
    profiles: [],
    password: "",
    createdAt: "",
  },
};
const ListUsers = () => {
  const lang = useSelector((state: any) => state.langReducer);

  const TABLE_HEAD = [
    { id: "id", label: "Id", alignRight: false },
    { id: "username", label: lang === "FR" ? "Nom" : "Username", alignRight: false },
    { id: "createdAt", label: lang === "FR" ? "Date Création" : "CreatedAt", alignRight: false },
    { id: "updatedAt", label: lang === "FR" ? "Date Modification" : "UpdatedAt", alignRight: false },
    { id: "" },
  ];
  const { register, handleSubmit, resetField } = useForm<any>({ mode: "onChange" });

  const [open, setOpen] = useState<any>(null);
  const [openAnchoEl, setOpenAnchorEl] = useState<any>(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState<any>("asc");

  const [selected, setSelected] = useState<Array<string>>([]);

  const [orderBy, setOrderBy] = useState<any>("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [profilesCompagny, setProfilesCompagny] = useState<Array<CompanyEmployeeResponse>>(MockUser);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - profilesCompagny.length) : 0;

  const filteredCompagny = applySortFilterOther(profilesCompagny, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredCompagny.length && !!filterName;

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [openFailed, setOpenFailed] = useState(false);
  const [openCreated, setOpenCreated] = useState(false);
  const [message, setMessage] = useState("");
  const [titleModal, setTitleModal] = useState("Nouvel utilisateur");
  const [isButtonCreated, setIsButtonCreated] = useState(true);
  const [token, setToken] = useState<any>("");
  const [profileChangeForUpdateOrSup, setProfileChangeForUpdateOrSup] = useState(profile);
  const [companyId, setCompanyId] = useState<any>("");
  const handleOpenMenu = (profile: ProfileCompgnyResponse) => {
    setOpen(true);
    setProfileChangeForUpdateOrSup(profile);
  };

  const handleAnchorEl = (event: any) => {
    setOpenAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event: ChangeEvent<HTMLInputElement>, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: string[] = profilesCompagny.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };

  const onSubmit = handleSubmit((data) => {
    if (isButtonCreated === true) {
      const roleCompanyEmployeeEnum = [];
      roleCompanyEmployeeEnum.push(RoleCompanyEmployeeEnum.EMPLOYEE);
      const userRole = [];
      userRole.push(UserRoleEnum.PUBLIC);
      const dataModelCreate: CreateUserForCompanyRequest = {
        companyId: companyId,
        createUserDto: {
          mail: data.mail,
          username: data.username,
          password: "company",
          roles: userRole,
        },
        createProfileDto: {
          usernameProfile: data.username,
          roleProfile: RoleProfileEnum.COMPANY,
        },
        companyEmployeeRoles: roleCompanyEmployeeEnum,
      };

      requestCreateApi(dataModelCreate, token);
    } else {
      requestModifyApi(data, token);
    }
  });

  const handleOpenCreateUser = () => {
    resetField("username");
    resetField("mail");
    resetField("profiles");
    resetField("roles");
    setIsButtonCreated(true);
    setTitleModal(lang === "FR" ? "Nouveaux Salarié" : "New Employee");
    handleOpen();
  };

  const handleDeleteUser = (data: ProfileCompgnyResponse) => {
    const dataDelete = {
      companyId: companyId,
      profileId: data.id,
    };
    requestDeleteApi(dataDelete, token);
  };

  const requestCreateApi = (data: CreateUserForCompanyRequest, token: string) => {
    CompanyService.createUserForCompany(data, token)
      .then((r) => {
        if (r.status === 201) {
          CompanyService.getEmployeebyCompanyByOwner(token)
            .then((r) => {
              if (r.status === 200) {
                setProfilesCompagny(r.data);
                setOpenCreated(true);
                setMessage("User crée");
                setOpenModal(false);
                setTimeout(() => {
                  setOpenCreated(false);
                }, 4000);
              }
            })
            .catch(() => {
              setOpenFailed(true);
              setMessage("User non récuperer");
              setOpenModal(false);
              setTimeout(() => {
                setOpenFailed(false);
              }, 4000);
            });
        }
      })
      .catch(() => {
        setOpenFailed(true);
        setMessage("User non crée");
        setTimeout(() => {
          setOpenFailed(false);
        }, 4000);
      });
  };

  const requestDeleteApi = (data: RemoveCompanyEmployeeRequest, token: string) => {
    CompanyService.removeCompanyEmployee(data, token)
      .then((r) => {
        if (r.status === 200) {
          CompanyService.getEmployeebyCompanyByOwner(token)
            .then((r) => {
              if (r.status === 200) {
                setProfilesCompagny(r.data);
                setOpenCreated(true);
                setMessage("User  supprimé");
                setOpenModal(false);
                handleCloseMenu();
                setTimeout(() => {
                  setOpenCreated(false);
                }, 4000);
              }
            })
            .catch(() => {
              setOpenFailed(true);
              setMessage("User non récuperer");
              setTimeout(() => {
                setOpenFailed(false);
              }, 4000);
            });
        }
      })
      .catch(() => {
        setOpenFailed(true);
        setMessage("Impossible de supprimé le user");
        setTimeout(() => {
          setOpenFailed(false);
        }, 4000);
      });
  };

  const requestModifyApi = (data: UpdateProfileRequest, token: string) => {
    ProfileService.updateMyProfile(data, token)
      .then((r) => {
        if (r.status === 200) {
          CompanyService.getEmployeebyCompanyByOwner(token)
            .then((r) => {
              if (r.status === 200) {
                setProfilesCompagny(r.data);
                setOpenCreated(true);
                setMessage("User mise a jours");
                setOpenModal(false);
                setTimeout(() => {
                  setOpenCreated(false);
                }, 4000);
              }
            })
            .catch(() => {
              setOpenFailed(true);
              setMessage("User non récuperer");
              setOpenModal(false);
              setTimeout(() => {
                setOpenFailed(false);
              }, 4000);
            });
        }
      })
      .catch(() => {
        setOpenFailed(true);
        setMessage("User non récuperer");
        setOpenModal(false);
        setTimeout(() => {
          setOpenFailed(false);
        }, 4000);
      });
  };

  useEffect(() => {
    if (AuthService.getUserConnected() != "" || AuthService.getUserConnected() != null) {
      setToken(AuthService.getUserConnected());
      if (!isEmpty(AuthService.getCompanyConnect())) {
        setCompanyId(AuthService.getCompanyConnect());
        CompanyService.getEmployeebyCompanyByOwner(token)
          .then((r) => {
            if (r.status === 200) {
              setProfilesCompagny(r.data);
            }
          })
      }
    }
  }, [token]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            borderRadius: 10,
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            border: "2px solid transparent",
          }}
        >
          <div className="container-modal">
            <div className="header-modal">
              <div className="title-header">{titleModal}</div>
              <img
                className="button-close"
                onClick={handleClose}
                src="/assets/logo/close_simple.svg"
                alt="close button"
              />
            </div>
            <form onSubmit={onSubmit} className="form-container">
              <div className="container-input-modal">
                <div className="title-input">
                  Username <span>*</span>
                </div>
                <input
                  className="input-container-modal"
                  type="text"
                  defaultValue={""}
                  placeholder="username"
                  {...register("username")}
                />
              </div>
              {isButtonCreated === true && (
                <div className="container-input-modal">
                  <div className="title-input">
                    Mail <span>*</span>
                  </div>
                  <input className="input-container-modal" type="text" placeholder="mail" {...register("mail")} />
                </div>
              )}
              {isButtonCreated === true && (
                <button className="button-upload">{lang === "FR" ? "Créer" : "Create"}</button>
              )}
              {isButtonCreated === false && (
                <button className="button-upload">{lang === "FR" ? "Modifier" : "Update"} </button>
              )}
            </form>
          </div>
        </div>
      </Modal>
      <div className="view-array">
        <Container sx={{ maxWidth: "100%" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {lang === "FR" ? "Manager vos salarié" : "Manager Employee"}
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleOpenCreateUser()}
              sx={{ background: "rgb(34, 10, 110)" }}
              startIcon={<AddIcon />}
            >
              {lang === "FR" ? "Nouveau" : "New Employee"}
            </Button>
          </Stack>

          <Card>
            <ArrayListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar sx={{ height: 400 }}>
              <TableContainer sx={{ minWidth: 800, maxHeight: 400, height: 400 }}>
                <Table sx={{ height: 400 }}>
                  <ArrayListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={profilesCompagny.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredCompagny
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row: any, index: number) => {
                        const { id, profile, createdAt, updatedAt } = row;
                        const selectedUser = selected.indexOf(id) !== -1;

                        return (
                          <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell padding="checkbox">
                              <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                            </TableCell>

                            <TableCell align="left">{id}</TableCell>
                            <TableCell align="left">{profile.user.username}</TableCell>

                            <TableCell align="left">{createdAt}</TableCell>
                            <TableCell align="left">{updatedAt}</TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={handleAnchorEl}>
                                <MoreVertIcon onClick={() => handleOpenMenu(row.profile)} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              {lang === "FR" ? "Non trouvé" : " Not found"}
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 35]}
              component="div"
              count={profilesCompagny.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>

        <Popover
          open={Boolean(open)}
          anchorEl={openAnchoEl}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          {/*
            * <MenuItem sx={{ color: "warning.main" }}>
            <ChangeCircleIcon sx={{ mr: 2 }} onClick={()=> handleOpenModifyUser(profileChangeForUpdateOrSup)}/>

            {lang === "FR" ? "Modifié" : "Update"}
          </MenuItem>
            * */}
          <MenuItem sx={{ color: "error.main" }} onClick={() => handleDeleteUser(profileChangeForUpdateOrSup)}>
            <DeleteIcon sx={{ mr: 2 }} />

            {lang === "FR" ? "Supprimé" : "Delete"}
          </MenuItem>
        </Popover>
      </div>
      <Snackbar open={openCreated} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ListUsers;
