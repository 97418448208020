import {GET_COMPANY_MEDIA} from "../../actions/API/media.company.action";


const initialState = {};

export default function mediaCompanyReducer(state = initialState, action) {
  switch (action.type) {
  case GET_COMPANY_MEDIA:
    return action.payload;
  default:
    return state;
  }
}
