import { filter } from "lodash";

type ComparatorFn<T> = (a: T, b: T) => number;
type StabilizedItem<T> = [T, number];

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<T>(order: "asc" | "desc", orderBy: keyof T): ComparatorFn<T> {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function applySortFilter<T extends { username: string }>(
  array: T[],
  comparator: ComparatorFn<T>,
  query?: string,
): T[] {
  const stabilizedThis: StabilizedItem<T>[] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.username.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export function applySortFilterFileManager<T extends { number: string }>(
  array: T[],
  comparator: ComparatorFn<T>,
  query?: string,
): T[] {
  const stabilizedThis: StabilizedItem<T>[] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.number.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export function applySortFilterEnterprise<T extends { name: string }>(
  array: T[],
  comparator: ComparatorFn<T>,
  query?: string,
): T[] {
  const stabilizedThis: StabilizedItem<T>[] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export function applySortFilterUsers<T extends { username: string }>(
  array: T[],
  comparator: ComparatorFn<T>,
  query?: string,
): T[] {
  const stabilizedThis: StabilizedItem<T>[] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.username.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export function applySortFilterAddress<T extends { state: string }>(
  array: T[],
  comparator: ComparatorFn<T>,
  query?: string,
): T[] {
  const stabilizedThis: StabilizedItem<T>[] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.state.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export function applySortFilterOther<T extends { id: string }>(
  array: T[],
  comparator: ComparatorFn<T>,
  query?: string,
): T[] {
  const stabilizedThis: StabilizedItem<T>[] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.id.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
