import http from "../http-common";
import { GetProfilesWithCriteriaRequest } from "../../models/entities/profile/request/get-profiles-with-criteria.request";
import { UpdateProfileRequest } from "../../models/entities/profile/request/update-profile.request";
import { CreateProfileRequest } from "../../models/entities/profile/request/create-profile.request";

const getAllProfiles = (token: string) => {
  return http.get<any>("/profile/admin/", { headers: { Authorization: "Bearer " + token } });
};

const getProfileById = (id: string, token: string) => {
  return http.get<any>(`/profile/public/get-my-profile/${id}`, { headers: { Authorization: "Bearer " + token } });
};
const getProfile = (id: string, token: string) => {
  return http.get<any>(`/profile/public/get-profile/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const getProfileByUserId = (id: string, token: string) => {
  return http.get<any>(`/profile/admin/with-user-id/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const getMyProfiles = (token: string) => {
  return http.get<any>("/profile/public/get-my-profiles", { headers: { Authorization: "Bearer " + token } });
};
const getProfilesWithCriteria = (data: GetProfilesWithCriteriaRequest, token: string) => {
  return http.post<any>("/profile/admin/get-with-criteria", data, { headers: { Authorization: "Bearer " + token } });
};

const updateProfile = (id: string, data: UpdateProfileRequest, token: string) => {
  return http.put<any>(`/profile/admin/update-profile/${id}`, data, { headers: { Authorization: "Bearer " + token } });
};

const updateMyProfile = (data: UpdateProfileRequest, token: string) => {
  return http.put<any>("/profile/public/update-my-profile", data, { headers: { Authorization: "Bearer " + token } });
};

const createProfile = (data: CreateProfileRequest, token: string) => {
  return http.post<any>("/profile/public/create-profile", data, { headers: { Authorization: "Bearer " + token } });
};

const createProfileAdmin = (data: CreateProfileRequest, token: string) => {
  return http.post<any>("/profile/admin/create-profile", data, { headers: { Authorization: "Bearer " + token } });
};
const deleteProfile = (id: string, token: string) => {
  return http.delete<any>(`/profile/admin/delete-profile/${id}`, { headers: { Authorization: "Bearer " + token } });
};

const deleteMyProfile = (id: string, token: string) => {
  return http.delete<any>(`/profile/admin/soft-delete-profile/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const restoreProfile = (id: string, data: any, token: string) => {
  return http.put<any>(`/profile/admin/restore-profile/${id}`, data, { headers: { Authorization: "Bearer " + token } });
};

const ProfileService = {
  getAllProfiles,
  getProfileById,
  getProfile,
  getProfileByUserId,
  getMyProfiles,
  getProfilesWithCriteria,
  updateProfile,
  updateMyProfile,
  createProfile,
  createProfileAdmin,
  deleteProfile,
  deleteMyProfile,
  restoreProfile,
};

export default ProfileService;
