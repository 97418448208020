import { useState } from "react";
import { alpha } from "@mui/material/styles";
import { Box, MenuItem, Stack, IconButton, Popover } from "@mui/material";
import MockLang from "../../mock/lang.mock";
import { getLANG } from "../../actions/API/langue.action";
import { useDispatch } from "react-redux";

export default function LanguagePopover() {
  const [open, setOpen] = useState<any>(null);
  const [language, setLanguage] = useState<number>(0);
  const dispatch: any = useDispatch();
  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChoiceLanguage = (index: number) => {
    setLanguage(index);
    setOpen(null);
    if (index === 0) {
      dispatch(getLANG("FR"));
    } else {
      dispatch(getLANG(""));
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          marginRight: 3,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <img src={MockLang[language].icon} alt={MockLang[language].label} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {MockLang.map((option, index) => (
            <MenuItem
              key={option.value}
              selected={option.value === MockLang[language].value}
              onClick={() => handleChoiceLanguage(index)}
            >
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  );
}
