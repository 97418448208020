import React, { Component, ReactElement, useEffect } from "react";
import Nav from "./nav/Nav";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { RouteProps, useNavigate } from "react-router-dom";
import AuthService from "../services/api/auth.service";

const GlobalStructure = (props: RouteProps) => {
  const { children } = props;
  const drawerWidth = 240;
  const navigate = useNavigate();
  useEffect(() => {
    if (AuthService.getUserConnected() == "") {
      AuthService.logout();
      navigate("/");
    }
  });
  return (
    <Box sx={{ display: "flex" }}>
      <Nav />
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default GlobalStructure;
