import { NotificationsType } from "../../../models/entities/Notification";
import { Avatar, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { fToNow } from "../../../utils/formattime.utils";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useEffect } from "react";
const NotificationItem = (notification: NotificationsType) => {
  const { title } = notification;

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          BackgroundColor: "action.selected",
        }),
      }}
    >
      <ListItemAvatar>
        <NotificationsActiveIcon />
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <AccessAlarmIcon sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
};
export default NotificationItem;
