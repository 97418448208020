import http from "../http-common";
import { AddConnectedCardRequest } from "../../models/entities/card/request/add-connected-card.request";
import { RemoveConnectedCardRequest } from "../../models/entities/card/request/remove-connected-card.request";
import { AddSavedCardRequest } from "../../models/entities/card/request/add-saved-card.request";
import { RemoveSavedCardRequest } from "../../models/entities/card/request/remove-saved-card.request";
import { CreateCardRequest } from "../../models/entities/card/request/create-card.request";
import { UpdateCardRequest } from "../../models/entities/card/request/update-card.request";
import { CardModel } from "../../models/entities/card/response/card.response";

const getAllCard = (token: string) => {
  return http.get<Array<any>>("/card/admin/get-all-cards", { headers: { Authorization: "Bearer " + token } });
};

const getCardById = (id: string, token: string) => {
  return http.get<Array<any>>(`/card/public/get-card-by-id//${id}`, { headers: { Authorization: "Bearer " + token } });
};

const getAllCardsByUserId = (token: string, userId: string) => {
  return http.get<Array<any>>(`/card/admin/get-all-cards/${userId}`, { headers: { Authorization: "Bearer " + token } });
};

const getAllMyCards = (token: string) => {
  return http.get<CardModel[]>("/card/public/get-all-my-cards", { headers: { Authorization: "Bearer " + token } });
};
const getAllMyCardsWithProfileId = (profileId: string, token: string) => {
  return http.get<any>(`/card/public/get-all-my-cards-by-profile-id/${profileId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getAllCardsWithProfileId = (profileId: string, token: string) => {
  return http.get<any>(`/card/admin/get-all-cards-by-profile-id/${profileId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getAllCardsWithCriteria = (token: string) => {
  return http.get<any>("/card/admin/get-with-criteria", { headers: { Authorization: "Bearer " + token } });
};

const getSavedCardsWithUserId = (userId: string, token: string) => {
  return http.get<any>(`/card/admin/get-saved-cards-with-user-id/${userId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getMySavedCardsWithUserId = (token: string) => {
  return http.get<any>("/card/public/get-my-saved-cards-with-user-id", {
    headers: { Authorization: "Bearer " + token },
  });
};

const getSavedCardsWithProfileId = (profileId: string, token: string) => {
  return http.get<any>(`/card/admin/get-saved-cards-with-profile-id/${profileId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getMySavedCardsWithProfileId = (profileId: string, token: string) => {
  return http.put<any>(`/card/public/get-my-saved-cards-with-profile-id/${profileId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const addConnectedCard = (data: AddConnectedCardRequest, token: string) => {
  return http.put<any>("/card/public/add-connected-card", data, { headers: { Authorization: "Bearer " + token } });
};

const removeConnectedCard = (data: RemoveConnectedCardRequest, token: string) => {
  return http.put<any>("/card/public/remove-connected-card", data, { headers: { Authorization: "Bearer " + token } });
};

const addConnectedCardToCard = (data: AddConnectedCardRequest, token: string) => {
  return http.post<any>("/card/admin/add-connected-card", data, { headers: { Authorization: "Bearer " + token } });
};

const addViewCount = (cardId: string) => {
  return http.put<any>(`/card/open/add-view-count/${cardId}`, {}, {});
};

const addSavedCardToMyCard = (data: AddSavedCardRequest, token: string) => {
  return http.put<any>("/card/public/add-saved-card-to-my-card", { headers: { Authorization: "Bearer " + token } });
};

const removeSavedCardFromMyCard = (data: RemoveSavedCardRequest, token: string) => {
  return http.post<any>("/card/public/remove-saved-card-from-my-card", {
    headers: { Authorization: "Bearer " + token },
  });
};
const createCard = (profileId: string, data: CreateCardRequest, token: string) => {
  return http.post<any>(`/card/admin/create-card-admin/${profileId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};
const createCardForMe = (profileId: string, data: CreateCardRequest, token: string) => {
  return http.post<any>(`/card/public/create-card/${profileId}`, { headers: { Authorization: "Bearer " + token } });
};
const deleteCard = (cardId: string, token: string) => {
  return http.delete<any>(`/card/admin/delete-card/${cardId}`, { headers: { Authorization: "Bearer " + token } });
};
const deleteMyCard = (cardId: string, token: string) => {
  return http.delete<any>(`/card/public/delete-card/${cardId}`, { headers: { Authorization: "Bearer " + token } });
};
const restoreCard = (cardId: string, token: string) => {
  return http.put<any>(`/card/admin/restore-card/${cardId}`, {}, { headers: { Authorization: "Bearer " + token } });
};
const softDeleteCard = (cardId: string, token: string) => {
  return http.post<any>(`/card/admin/soft-delete-card/${cardId}`, { headers: { Authorization: "Bearer " + token } });
};
const removeConnectedCardFromCard = (data: RemoveConnectedCardRequest, token: string) => {
  return http.post<any>("/card/admin/remove-connected-card", { headers: { Authorization: "Bearer " + token } });
};
const updateCard = (cardId: string, data: UpdateCardRequest, token: string) => {
  return http.post<any>(`/card/admin/update-card/${cardId}`, data, { headers: { Authorization: "Bearer " + token } });
};

const getAllMyConnectedCards = (token: string) => {
  return http.get<CardModel[]>("/card/public/get-all-my-connected-cards/", {
    headers: { Authorization: "Bearer " + token },
  });
};

const CardService = {
  getAllCard,
  getCardById,
  getAllCardsByUserId,
  getAllMyCards,
  getAllMyCardsWithProfileId,
  getAllCardsWithProfileId,
  getAllCardsWithCriteria,
  getSavedCardsWithUserId,
  getMySavedCardsWithUserId,
  getSavedCardsWithProfileId,
  getMySavedCardsWithProfileId,
  addConnectedCard,
  removeConnectedCard,
  addConnectedCardToCard,
  removeConnectedCardFromCard,
  addViewCount,
  addSavedCardToMyCard,
  removeSavedCardFromMyCard,
  createCard,
  createCardForMe,
  deleteCard,
  deleteMyCard,
  restoreCard,
  softDeleteCard,
  updateCard,
  getAllMyConnectedCards,
};

export default CardService;
