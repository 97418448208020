import React from "react";
import { Box, Typography } from "@mui/material";
import TabPanel from "../../components/mui/TabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import GeneralEntreprise from "../../components/entreprise/general/GeneralEntreprise";
import Billing from "../../components/entreprise/billing/Billing";
import NotificationSettings from "../../components/entreprise/notifications-settings/NotificationSettings";
import Security from "../../components/entreprise/security/Security";
import { useSelector } from "react-redux";

const Enterprise = () => {
  const [value, setValue] = React.useState(0);
  const lang = useSelector((state: any) => state.langReducer);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className="view--entreprise">
      <Typography variant="h4" sx={{ marginBottom: 5 }}>
        {lang === "FR" ? "Entreprise" : "Company"}
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Général" {...GeneralEntreprise} sx={{ fontSize: 12 }} />
            {/* <Tab label="Billings" {...Billing} sx={{ fontSize: 12 }} />
            <Tab label="Notification" {...NotificationSettings} sx={{ fontSize: 12 }} />
            */}
            <Tab label="Sécurity" {...Security} sx={{ fontSize: 12 }} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <GeneralEntreprise />
        </TabPanel>
        {/*
          <TabPanel value={value} index={1}>
          <Billing />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <NotificationSettings />
        </TabPanel>
        */}
        <TabPanel value={value} index={1}>
          <Security />
        </TabPanel>
      </Box>
    </div>
  );
};

export default Enterprise;
