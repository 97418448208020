import http from "../http-common";

const getCountAllCart = (token: string) => {
  return http.get<any>("/card/admin/get-all-card-count", { headers: { Authorization: "Bearer " + token } });
};

const getAllCompagnyCount = (token: string) => {
  return http.get<any>("/company/admin/get-all-company-count", { headers: { Authorization: "Bearer " + token } });
};

const getAllCardCountByCompagny = (id: string, token: string) => {
  return http.get<any>(`/company/company/get-all-card-company-count-by-company-id/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getAllemployeeCountByEntreprise = (id: string, token: string) => {
  return http.get<any>(`/company/company/get-employee-count-by-company-id/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getAllCardViewCountByEntreprise = (id: string, token: string) => {
  return http.get<any>(`/company/company/get-all-card-view-count-by-company-id/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getAllCardForwardCountByEntreprise = (id: string, token: string) => {
  return http.get<any>(`/company/company/get-all-card-forward-count-by-company-id/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getCountAllSubscription = (token: string) => {
  return http.get<any>("/subscription/admin/get-all-active-subscriptions-count", {
    headers: { Authorization: "Bearer " + token },
  });
};

const getCountAllProfile = (token: string) => {
  return http.get<any>("/profile/admin/get-all-profile-count", { headers: { Authorization: "Bearer " + token } });
};

const getCountAllUser = (token: string) => {
  return http.get<any>("/user/admin/get-all-user-count", { headers: { Authorization: "Bearer " + token } });
};

const getAllEmployeePersonalCardView = (companyId: string, token: string) => {
  return http.get<any>(`/company/company/chart/get-all-employee-personal-card-view/${companyId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};
const getCompanyForwardCard = (companyId: string, token: string) => {
  return http.get<any>(`/company/company/chart/get-company-forward-card/${companyId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};
const getCompanyReceivedCard = (companyId: string, token: string) => {
  return http.get<any>(`/company/company/chart/get-company-received-card/${companyId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const StatsService = {
  getCountAllUser,
  getAllCardCountByCompagny,
  getCountAllProfile,
  getCountAllCart,
  getAllCompagnyCount,
  getAllemployeeCountByEntreprise,
  getAllCardViewCountByEntreprise,
  getAllCardForwardCountByEntreprise,
  getCountAllSubscription,
  getAllEmployeePersonalCardView,
  getCompanyForwardCard,
  getCompanyReceivedCard,
};

export default StatsService;
