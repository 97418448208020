import { Box, Card, CardHeader } from "@mui/material";
import { ICircleChart } from "../../models/component/chart/circlechart.model";
import StyledChartWrapper from "../../sass/mui/mui.circlechart";
import { Line, Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default function CircleChart(props: ICircleChart) {
  const { title, subheader, chartlabels, chartData } = props;

  return (
    <Card sx={{ width: "50%", padding: 5, display: "flex", flexDirection: "column", alignItems: "center" }}>
      <CardHeader title={title} subheader={subheader} />
      <Box>
        <Pie
          data={{
            labels: chartlabels,
            datasets: [
              {
                data: chartData,
                borderColor: ["rgb(0, 0, 0)"],
                backgroundColor: ["rgb(75, 231, 72)", "rgb(255, 165, 0)"],
                borderWidth: 0,
                borderJoinStyle: "bevel",
              },
            ],
          }}
          width={400}
          height={600}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </Box>
    </Card>
  );
}
