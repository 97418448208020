import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {ChangeEvent} from "react";
import ColorPicker from "../../../views/card-design/ColorPicker";

const firstStep = (isActive,setIsActive,setDataColor) => {
  const colors = ["#008be5","#f8d7da","#008be5","#000","#22286F","#BEBEBE","#212b36","#212b36","#FFF222","#ff0000",]
  const lang  = useSelector((state) => state.langReducer);

  const handleChoice = (value,index) => {
    if(isActive){
      setIsActive(false)
      document.getElementById(index).classList.remove("border")
    }else{
      setIsActive(true)
      setDataColor(value)
      document.getElementById(index).classList.add("border")
    }
  }


  const handleColorChange = (color) => {
    console.log("Couleur sélectionnée :", color);
    setDataColor(color)
  };


  return(
    <div className="component--firststep-stepper">
      <div className="title-container">{lang === "FR" ? "Selectionné votre couleur" : "Select Colors card design"} </div>
      <div className="description"> {lang === "FR" ? "Cette section concerne le choix de couleur de votre carte" : "This section concerns the choice of color for your card."}</div>
      <div className="container-choice-color">
        <ColorPicker onChange={handleColorChange} />
      </div>
    </div>
  );
}

export default firstStep;
