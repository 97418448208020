import { ChangeEvent, useEffect, useState } from "react";

import {
  Card,
  Table,
  Stack,
  Paper,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from "@mui/material";


import {
  applySortFilterOther,
  getComparator,
} from "../../utils/array-user-list";
import UserListToolbar from "../../components/list-users/UserListToolbar";
import Scrollbar from "../../components/mui/scrollbar/Scrollbar";
import UserListHead from "../../components/list-users/UserListHead";
import FileManagerMock from "../../mock/file-manager.mock";
import { InvoiceResponse } from "../../models/entities/finance/invoice/invoice.response";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import AuthService from "../../services/api/auth.service";
import InvoiceService from "../../services/api/finance/invoices.service";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------
/*
 *
 * */

const array = [{
  id: "208378198389127123",
  object: "",
  account_country: "",
  account_name: "test1",
  account_tax_ids: null,
  amount_due: 0,
  amount_paid: 0,
  amount_remaining: 0,
  amount_shipping: 0,
  application: null,
  application_fee_amount: null,
  attempt_count: 0,
  attempted: true,
  auto_advance: false,
  automatic_tax: {
    enabled: true,
    status: "complete",
  },
  billing_reason: "",
  charge: "",
  collection_method: "",
  created: 0,
  currency: "",
  custom_fields: null,
  customer: "",
  customer_address: {
    city: "",
    country: "",
    line1: "",
    line2: "",
    postal_code: "",
    state: "",
  },
  customer_email: "",
  customer_name: "",
  customer_phone: "+",
  customer_shipping: {
    address: {
      city: "",
      country: "",
      line1: "  ",
      line2: "",
      postal_code: "",
      state: "",
    },
    name: "",
    phone: "",
  },
  customer_tax_exempt: "",
  customer_tax_ids: [],
  default_payment_method: null,
  default_source: null,
  default_tax_rates: [],
  description: null,
  discount: null,
  discounts: [],
  due_date: null,
  ending_balance: 0,
  footer: null,
  from_invoice: null,
  hosted_invoice_url: "",
  invoice_pdf: "test.pdf",
  last_finalization_error: null,
  latest_revision: null,
  lines: {
    object: "",
    data: [
      {
        id: "",
        object: "",
        amount: 0,
        amount_excluding_tax: 0,
        currency: "",
        description: "",
        discount_amounts: [],
        discountable: true,
        discounts: [],
        livemode: false,
        metadata: {},
        period: {
          end: 0,
          start: 0,
        },
        plan: {
          id: "",
          object: "",
          active: true,
          aggregate_usage: null,
          amount: 0,
          amount_decimal: "",
          billing_scheme: "",
          created: 0,
          currency: "",
          interval: "",
          interval_count: 1,
          livemode: false,
          metadata: {},
          nickname: null,
          product: "",
          tiers_mode: null,
          transform_usage: null,
          trial_period_days: 0,
          usage_type: "",
        },
        price: {
          id: "",
          object: "",
          active: true,
          billing_scheme: "",
          created: 0,
          currency: "",
          custom_unit_amount: null,
          livemode: false,
          lookup_key: null,
          metadata: {},
          nickname: null,
          product: "",
          recurring: {
            aggregate_usage: null,
            interval: "month",
            interval_count: 0,
            trial_period_days: 0,
            usage_type: "",
          },
          tax_behavior: "",
          tiers_mode: null,
          transform_quantity: null,
          type: "recurring",
          unit_amount: 0,
          unit_amount_decimal: "",
        },
        proration: false,
        proration_details: {
          credited_items: null,
        },
        quantity: 1,
        subscription: "",
        subscription_item: "",
        tax_amounts: [
          {
            amount: 0,
            inclusive: true,
            tax_rate: "",
            taxability_reason: "",
            taxable_amount: 0,
          },
        ],
        tax_rates: [],
        type: "subscription",
        unit_amount_excluding_tax: "",
      },
    ],
    has_more: false,
    total_count: 1,
    url: "",
  },
  livemode: false,
  metadata: {},
  next_payment_attempt: null,
  number: "",
  on_behalf_of: null,
  paid: true,
  paid_out_of_band: false,
  payment_intent: "",
  payment_settings: {
    default_mandate: null,
    payment_method_options: null,
    payment_method_types: null,
  },
  period_end: 0,
  period_start: 0,
  post_payment_credit_notes_amount: 0,
  pre_payment_credit_notes_amount: 0,
  quote: null,
  receipt_number: null,
  rendering_options: null,
  shipping_cost: null,
  shipping_details: null,
  starting_balance: 0,
  statement_descriptor: null,
  status: "paid",
  status_transitions: {
    finalized_at: 0,
    marked_uncollectible_at: null,
    paid_at: 0,
    voided_at: null,
  },
  subscription: "",
  subtotal: 0,
  subtotal_excluding_tax: 0,
  tax: 0,
  test_clock: null,
  total: 100000,
  total_discount_amounts: [],
  total_excluding_tax: 0,
  total_tax_amounts: [
    {
      amount: 0,
      inclusive: true,
      tax_rate: "",
      taxability_reason: "",
      taxable_amount: 0,
    },
  ],
  transfer_data: null,
  webhooks_delivered_at: 0,
},{
  id: "2088888198389127123",
  object: "",
  account_country: "",
  account_name: "test2",
  account_tax_ids: null,
  amount_due: 0,
  amount_paid: 0,
  amount_remaining: 0,
  amount_shipping: 0,
  application: null,
  application_fee_amount: null,
  attempt_count: 0,
  attempted: true,
  auto_advance: false,
  automatic_tax: {
    enabled: true,
    status: "complete",
  },
  billing_reason: "",
  charge: "",
  collection_method: "",
  created: 0,
  currency: "",
  custom_fields: null,
  customer: "",
  customer_address: {
    city: "",
    country: "",
    line1: "",
    line2: "",
    postal_code: "",
    state: "",
  },
  customer_email: "",
  customer_name: "",
  customer_phone: "+",
  customer_shipping: {
    address: {
      city: "",
      country: "",
      line1: "  ",
      line2: "",
      postal_code: "",
      state: "",
    },
    name: "",
    phone: "",
  },
  customer_tax_exempt: "",
  customer_tax_ids: [],
  default_payment_method: null,
  default_source: null,
  default_tax_rates: [],
  description: null,
  discount: null,
  discounts: [],
  due_date: null,
  ending_balance: 0,
  footer: null,
  from_invoice: null,
  hosted_invoice_url: "",
  invoice_pdf: "test.pdf",
  last_finalization_error: null,
  latest_revision: null,
  lines: {
    object: "",
    data: [
      {
        id: "",
        object: "",
        amount: 0,
        amount_excluding_tax: 0,
        currency: "",
        description: "",
        discount_amounts: [],
        discountable: true,
        discounts: [],
        livemode: false,
        metadata: {},
        period: {
          end: 0,
          start: 0,
        },
        plan: {
          id: "",
          object: "",
          active: true,
          aggregate_usage: null,
          amount: 0,
          amount_decimal: "",
          billing_scheme: "",
          created: 0,
          currency: "",
          interval: "",
          interval_count: 1,
          livemode: false,
          metadata: {},
          nickname: null,
          product: "",
          tiers_mode: null,
          transform_usage: null,
          trial_period_days: 0,
          usage_type: "",
        },
        price: {
          id: "",
          object: "",
          active: true,
          billing_scheme: "",
          created: 0,
          currency: "",
          custom_unit_amount: null,
          livemode: false,
          lookup_key: null,
          metadata: {},
          nickname: null,
          product: "",
          recurring: {
            aggregate_usage: null,
            interval: "month",
            interval_count: 0,
            trial_period_days: 0,
            usage_type: "",
          },
          tax_behavior: "",
          tiers_mode: null,
          transform_quantity: null,
          type: "recurring",
          unit_amount: 0,
          unit_amount_decimal: "",
        },
        proration: false,
        proration_details: {
          credited_items: null,
        },
        quantity: 1,
        subscription: "",
        subscription_item: "",
        tax_amounts: [
          {
            amount: 0,
            inclusive: true,
            tax_rate: "",
            taxability_reason: "",
            taxable_amount: 0,
          },
        ],
        tax_rates: [],
        type: "subscription",
        unit_amount_excluding_tax: "",
      },
    ],
    has_more: false,
    total_count: 1,
    url: "",
  },
  livemode: false,
  metadata: {},
  next_payment_attempt: null,
  number: "",
  on_behalf_of: null,
  paid: true,
  paid_out_of_band: false,
  payment_intent: "",
  payment_settings: {
    default_mandate: null,
    payment_method_options: null,
    payment_method_types: null,
  },
  period_end: 0,
  period_start: 0,
  post_payment_credit_notes_amount: 0,
  pre_payment_credit_notes_amount: 0,
  quote: null,
  receipt_number: null,
  rendering_options: null,
  shipping_cost: null,
  shipping_details: null,
  starting_balance: 0,
  statement_descriptor: null,
  status: "paid",
  status_transitions: {
    finalized_at: 0,
    marked_uncollectible_at: null,
    paid_at: 0,
    voided_at: null,
  },
  subscription: "",
  subtotal: 0,
  subtotal_excluding_tax: 0,
  tax: 0,
  test_clock: null,
  total: 100000,
  total_discount_amounts: [],
  total_excluding_tax: 0,
  total_tax_amounts: [
    {
      amount: 0,
      inclusive: true,
      tax_rate: "",
      taxability_reason: "",
      taxable_amount: 0,
    },
  ],
  transfer_data: null,
  webhooks_delivered_at: 0,
},{
  id: "208378199999127123",
  object: "",
  account_country: "",
  account_name: "test3",
  account_tax_ids: null,
  amount_due: 0,
  amount_paid: 0,
  amount_remaining: 0,
  amount_shipping: 0,
  application: null,
  application_fee_amount: null,
  attempt_count: 0,
  attempted: true,
  auto_advance: false,
  automatic_tax: {
    enabled: true,
    status: "complete",
  },
  billing_reason: "",
  charge: "",
  collection_method: "",
  created: 0,
  currency: "",
  custom_fields: null,
  customer: "",
  customer_address: {
    city: "",
    country: "",
    line1: "",
    line2: "",
    postal_code: "",
    state: "",
  },
  customer_email: "",
  customer_name: "",
  customer_phone: "+",
  customer_shipping: {
    address: {
      city: "",
      country: "",
      line1: "  ",
      line2: "",
      postal_code: "",
      state: "",
    },
    name: "",
    phone: "",
  },
  customer_tax_exempt: "",
  customer_tax_ids: [],
  default_payment_method: null,
  default_source: null,
  default_tax_rates: [],
  description: null,
  discount: null,
  discounts: [],
  due_date: null,
  ending_balance: 0,
  footer: null,
  from_invoice: null,
  hosted_invoice_url: "",
  invoice_pdf: "test.pdf",
  last_finalization_error: null,
  latest_revision: null,
  lines: {
    object: "",
    data: [
      {
        id: "",
        object: "",
        amount: 0,
        amount_excluding_tax: 0,
        currency: "",
        description: "",
        discount_amounts: [],
        discountable: true,
        discounts: [],
        livemode: false,
        metadata: {},
        period: {
          end: 0,
          start: 0,
        },
        plan: {
          id: "",
          object: "",
          active: true,
          aggregate_usage: null,
          amount: 0,
          amount_decimal: "",
          billing_scheme: "",
          created: 0,
          currency: "",
          interval: "",
          interval_count: 1,
          livemode: false,
          metadata: {},
          nickname: null,
          product: "",
          tiers_mode: null,
          transform_usage: null,
          trial_period_days: 0,
          usage_type: "",
        },
        price: {
          id: "",
          object: "",
          active: true,
          billing_scheme: "",
          created: 0,
          currency: "",
          custom_unit_amount: null,
          livemode: false,
          lookup_key: null,
          metadata: {},
          nickname: null,
          product: "",
          recurring: {
            aggregate_usage: null,
            interval: "month",
            interval_count: 0,
            trial_period_days: 0,
            usage_type: "",
          },
          tax_behavior: "",
          tiers_mode: null,
          transform_quantity: null,
          type: "recurring",
          unit_amount: 0,
          unit_amount_decimal: "",
        },
        proration: false,
        proration_details: {
          credited_items: null,
        },
        quantity: 1,
        subscription: "",
        subscription_item: "",
        tax_amounts: [
          {
            amount: 0,
            inclusive: true,
            tax_rate: "",
            taxability_reason: "",
            taxable_amount: 0,
          },
        ],
        tax_rates: [],
        type: "subscription",
        unit_amount_excluding_tax: "",
      },
    ],
    has_more: false,
    total_count: 1,
    url: "",
  },
  livemode: false,
  metadata: {},
  next_payment_attempt: null,
  number: "",
  on_behalf_of: null,
  paid: true,
  paid_out_of_band: false,
  payment_intent: "",
  payment_settings: {
    default_mandate: null,
    payment_method_options: null,
    payment_method_types: null,
  },
  period_end: 0,
  period_start: 0,
  post_payment_credit_notes_amount: 0,
  pre_payment_credit_notes_amount: 0,
  quote: null,
  receipt_number: null,
  rendering_options: null,
  shipping_cost: null,
  shipping_details: null,
  starting_balance: 0,
  statement_descriptor: null,
  status: "paid",
  status_transitions: {
    finalized_at: 0,
    marked_uncollectible_at: null,
    paid_at: 0,
    voided_at: null,
  },
  subscription: "",
  subtotal: 0,
  subtotal_excluding_tax: 0,
  tax: 0,
  test_clock: null,
  total: 100000,
  total_discount_amounts: [],
  total_excluding_tax: 0,
  total_tax_amounts: [
    {
      amount: 0,
      inclusive: true,
      tax_rate: "",
      taxability_reason: "",
      taxable_amount: 0,
    },
  ],
  transfer_data: null,
  webhooks_delivered_at: 0,
}]

const MockInvoice: Array<InvoiceResponse> = [
  {
    id: "",
    object: "",
    account_country: "",
    account_name: "",
    account_tax_ids: null,
    amount_due: 0,
    amount_paid: 0,
    amount_remaining: 0,
    amount_shipping: 0,
    application: null,
    application_fee_amount: null,
    attempt_count: 0,
    attempted: true,
    auto_advance: false,
    automatic_tax: {
      enabled: true,
      status: "complete",
    },
    billing_reason: "",
    charge: "",
    collection_method: "",
    created: 0,
    currency: "",
    custom_fields: null,
    customer: "",
    customer_address: {
      city: "",
      country: "",
      line1: "",
      line2: "",
      postal_code: "",
      state: "",
    },
    customer_email: "",
    customer_name: "",
    customer_phone: "+",
    customer_shipping: {
      address: {
        city: "",
        country: "",
        line1: "  ",
        line2: "",
        postal_code: "",
        state: "",
      },
      name: "",
      phone: "",
    },
    customer_tax_exempt: "",
    customer_tax_ids: [],
    default_payment_method: null,
    default_source: null,
    default_tax_rates: [],
    description: null,
    discount: null,
    discounts: [],
    due_date: null,
    ending_balance: 0,
    footer: null,
    from_invoice: null,
    hosted_invoice_url: "",
    invoice_pdf: "",
    last_finalization_error: null,
    latest_revision: null,
    lines: {
      object: "",
      data: [
        {
          id: "",
          object: "",
          amount: 0,
          amount_excluding_tax: 0,
          currency: "",
          description: "",
          discount_amounts: [],
          discountable: true,
          discounts: [],
          livemode: false,
          metadata: {},
          period: {
            end: 0,
            start: 0,
          },
          plan: {
            id: "",
            object: "",
            active: true,
            aggregate_usage: null,
            amount: 0,
            amount_decimal: "",
            billing_scheme: "",
            created: 0,
            currency: "",
            interval: "",
            interval_count: 1,
            livemode: false,
            metadata: {},
            nickname: null,
            product: "",
            tiers_mode: null,
            transform_usage: null,
            trial_period_days: 0,
            usage_type: "",
          },
          price: {
            id: "",
            object: "",
            active: true,
            billing_scheme: "",
            created: 0,
            currency: "",
            custom_unit_amount: null,
            livemode: false,
            lookup_key: null,
            metadata: {},
            nickname: null,
            product: "",
            recurring: {
              aggregate_usage: null,
              interval: "month",
              interval_count: 0,
              trial_period_days: 0,
              usage_type: "",
            },
            tax_behavior: "",
            tiers_mode: null,
            transform_quantity: null,
            type: "recurring",
            unit_amount: 0,
            unit_amount_decimal: "",
          },
          proration: false,
          proration_details: {
            credited_items: null,
          },
          quantity: 1,
          subscription: "",
          subscription_item: "",
          tax_amounts: [
            {
              amount: 0,
              inclusive: true,
              tax_rate: "",
              taxability_reason: "",
              taxable_amount: 0,
            },
          ],
          tax_rates: [],
          type: "subscription",
          unit_amount_excluding_tax: "",
        },
      ],
      has_more: false,
      total_count: 1,
      url: "",
    },
    livemode: false,
    metadata: {},
    next_payment_attempt: null,
    number: "",
    on_behalf_of: null,
    paid: true,
    paid_out_of_band: false,
    payment_intent: "",
    payment_settings: {
      default_mandate: null,
      payment_method_options: null,
      payment_method_types: null,
    },
    period_end: 0,
    period_start: 0,
    post_payment_credit_notes_amount: 0,
    pre_payment_credit_notes_amount: 0,
    quote: null,
    receipt_number: null,
    rendering_options: null,
    shipping_cost: null,
    shipping_details: null,
    starting_balance: 0,
    statement_descriptor: null,
    status: "",
    status_transitions: {
      finalized_at: 0,
      marked_uncollectible_at: null,
      paid_at: 0,
      voided_at: null,
    },
    subscription: "",
    subtotal: 0,
    subtotal_excluding_tax: 0,
    tax: 0,
    test_clock: null,
    total: 0,
    total_discount_amounts: [],
    total_excluding_tax: 0,
    total_tax_amounts: [
      {
        amount: 0,
        inclusive: true,
        tax_rate: "",
        taxability_reason: "",
        taxable_amount: 0,
      },
    ],
    transfer_data: null,
    webhooks_delivered_at: 0,
  },
];


// ----------------------------------------------------------------------

const FileManager = () => {

  const lang = useSelector((state: any) => state.langReducer);
  const TABLE_HEAD = [
    { id: "id", label: "Id", alignRight: false },
    { id: "total", label: "Total", alignRight: false },
    { id: "" },
  ];


  const [page, setPage] = useState(0);

  const [order, setOrder] = useState<any>("asc");

  const [selected, setSelected] = useState<Array<string>>([]);

  const [orderBy, setOrderBy] = useState<any>("name");
  const [invoiceCompagny, setInvoiceCompagny] = useState<Array<InvoiceResponse>>(MockInvoice);

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invoiceCompagny.length) : 0;

  const filteredInvoice = applySortFilterOther(array, getComparator(order, orderBy), filterName);
  const [token, setToken] = useState<any>("");

  const isNotFound = !filteredInvoice.length && !!filterName;


  const handleRequestSort = (event: ChangeEvent<HTMLInputElement>, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: string[] = invoiceCompagny.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    if (AuthService.getUserConnected() != "" || AuthService.getUserConnected() != null) {
      setToken(AuthService.getUserConnected());
      InvoiceService.getAllInvoice(token)
        .then((r) => {
          if (r.status === 200) {
            setInvoiceCompagny(r.data.data);
          }
        })
        .catch(() => console.log(""));
    }
  }, [token]);

  return (
    <div className="view-array">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {lang === "FR" ? "Documents & Factures" : "Documents & Invoices"}
          </Typography>
          <></>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar sx={{ height: 400 }}>
            <TableContainer sx={{ minWidth: 800, maxHeight: 400, height: 400 }}>
              <Table sx={{ height: 400 }}>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={FileManagerMock.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredInvoice.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => {
                    const { id, total } = row;
                    const selectedUser = selected.indexOf(id) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        <TableCell align="left">{id}</TableCell>

                        <TableCell align="left">{total / 100} euros</TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit">
                            <a style={{ textDecoration: "none" }} href={row.invoice_pdf} download>
                              <ArrowCircleDownIcon />
                            </a>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            {lang === "FR" ? "Non trouvé" : " Not found"}
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20, 35]}
            component="div"
            count={invoiceCompagny.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </div>
  );
};

export default FileManager;
