import { useEffect, useState } from "react";

import {
  Box,
  List,
  Badge,
  Button,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListSubheader,
} from "@mui/material";

import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import NotificationItem from "./NotificationItem";
import Scrollbar from "../../../components/mui/scrollbar/Scrollbar";
import isEmpty from "../../../utils/isempty.utils";
import AuthService from "../../../services/api/auth.service";
import { NotificationResponse } from "../../../models/entities/notification/response/notification.response";
import { NotificationTypeEnum } from "../../../models/enum/notification-type.enum";
import NotificationService from "../../../services/api/notification.service";
import { Check } from "@mui/icons-material";

const mockNotif: Array<NotificationResponse> = [
  {
    id: "",
    title: "",
    description: "",
    type: NotificationTypeEnum.INFO,
    isRead: false,
    link: "",
    createdAt: new Date(),
  },
];
export default function NotificationsPopover() {
  const [notifications, setNotifications] = useState<Array<NotificationResponse>>(mockNotif);

  const totalUnRead = notifications.filter((item) => item.isRead === false).length;

  const [open, setOpen] = useState(null);

  
  const totalUnReadALL = notifications.filter((item) => item.isRead === false);
  const [token, setToken] = useState<any>("");
  const [notificall, setNotificationCall] = useState<any>(false);
 
  const handleAllUnread = () => {
    const arrayId: string[] = totalUnReadALL.map((value: any) => value.id);
    NotificationService.markNotificationAsReadAll({ notificationIds: arrayId }, token)
      .then((r) => {
        if (r.status === 204) {
          setNotificationCall(!notificall);
        }
      })
      .catch(() => {
        console.log("");
      });
  };
 
  useEffect(() => {
    if (!isEmpty(AuthService.getUserConnected())) {
      setToken(AuthService.getUserConnected());
      console.log();
    }
  }, [token]);
 
  useEffect(() => {
    if (token != "") {
      NotificationService.getUnreadNotificationsForUser(token)
        .then((r) => {
          if (r.status === 200) {
            setNotifications(r.data);
            console.log(r.data);
          }
        })
        .catch(() => {
          console.log("");
        });
    }
  }, [token, notificall]);
  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40, marginRight: 3 }}
      >
        <Badge badgeContent={0} color="error">
          <NotificationAddIcon />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>
          <Button onClick={() => handleAllUnread()}>
            <Check />
          </Button>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: "overline" }}>
                New
              </ListSubheader>
            }
          >
            {notifications.length > 0 &&
              notifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  createdAt={notification.createdAt}
                  description={notification.description}
                  id={notification.id}
                  isUnRead={notification.isRead}
                  title={notification.title}
                  type={notification.type}
                />
              ))}
          </List>
        </Scrollbar>
      </Popover>
    </>
  );
}
