import { faker } from "@faker-js/faker";
import { IFileModel } from "../models/entities/file.model";

// ----------------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FileManagerArrayMock: IFileModel[] = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  number: `N° ${faker.datatype.uuid()}`,
  date: `${new Date().getDay()}/${new Date().getMonth()}/${new Date().getFullYear()}`,
  type: "file",
}));

export default FileManagerArrayMock;
