import { Avatar } from "@mui/material";
import MuiUserUtils from "../../utils/mui.user.utils";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AuthService from "../../services/api/auth.service";
import { getMe } from "../../actions/API/user.action";
import isEmpty from "../../utils/isempty.utils";
import { getMeCompagny } from "../../actions/API/company.actions";

const CardUserMinimal = () => {
  const dispatch: any = useDispatch();
  const user = useSelector((state: any) => state.userReducer);
  const [name, setName] = useState<string>("test gggg");

  useEffect(() => {
    if (AuthService.getUserConnected() != "") {
      dispatch(getMe(AuthService.getUserConnected()));
      dispatch(getMeCompagny(AuthService.getUserConnected()));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(user)) {
      setName(user.username);
    } else {
      setName("John Doe");
    }
  }, [user]);

  return (
    <div className="component-card-user-minimal">
      <Avatar
        {...MuiUserUtils.stringAvatar(name)}
        sx={{ backgroundColor: "rgba(34, 10, 110,1)", borderRadius: "50%" }}
      />
      <div className="user-information">
        <div className="title">John Doe</div>
        <div className="subtitle">john.doe@mail.com</div>
      </div>
    </div>
  );
};

export default CardUserMinimal;
