import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/api/auth.service";

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AuthService.logout();
    navigate("/");
  });
  return <div></div>;
};

export default Logout;
