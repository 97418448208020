function randomInt(number1: number, number2: number) {
  if (number1 > number2) {
    // Échange les valeurs si number1 est plus grand que number2
    [number1, number2] = [number2, number1];
  }

  // Calcul de la plage de nombres possibles
  const range = number2 - number1 + 1;

  // Génère un nombre aléatoire entre 0 et range-1
  const randomOffset = Math.floor(Math.random() * range);

  // Ajoute l'offset à number1 pour obtenir un nombre dans la plage souhaitée
  const randomNumber = number1 + randomOffset;

  return randomNumber;
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < randomInt(1, 5); i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function getFirstLettersString(str: string, delimiter: string) {
  const splitArray = str.split(delimiter);
  const firstLetters = splitArray.map((word) => word.charAt(0));
  const resultString = firstLetters.join("");
  return resultString;
}

function stringAvatar(name: string) {
  return {
    sx: {
      backgroundColor: "rgb(34, 10, 110)",
    },
    children: getFirstLettersString(name, " "),
  };
}

const MuiUserUtils = {
  stringAvatar,
  stringToColor,
};

export default MuiUserUtils;
