import {  useState } from "react";
import { useForm } from "react-hook-form";
import { Auth } from "../../models/auth.model";
import { useNavigate } from "react-router-dom";
import resolver from "../../resolver/auth.resolver";
import { Alert, Snackbar } from "@mui/material";
import {  useSelector } from "react-redux";


const Login = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Auth>({ resolver });

  const [openFailed, setOpenFailed] = useState(false);
  const [message, setMessage] = useState("");
  //const userConnected = AuthService.getUserConnected();
  //const company = useSelector((state: any) => state.company);
  const lang = useSelector((state: any) => state.langReducer);

  /* function access(arr: string[]): boolean {
    return arr.some((value) => value === "COMPANY_ACCOUNT" || value === "ADMIN" || value === "SUPER_ADMIN");
  }*/

  const onSubmit = handleSubmit((data) => {
    setOpenFailed(true);
    if(data.login === "DashboardEnterprise" && data.password === "DashboardEnterprise123@"){
      navigate("/dashboard");
      sessionStorage.setItem("token", "falseqdqsdq");
    }else {
      setMessage("Vous n'avez pas les droits");
    }
    //sessionStorage.setItem("token", "r.data.currentHashedRefreshToken");
    /*AuthService.login(data)
      .then((r) => {
        if (r.status === 200) {
          if (access(r.data.roles)) {
            dispatch(getMe(r.data.currentHashedRefreshToken));
            sessionStorage.setItem("token", r.data.currentHashedRefreshToken);
            CompagnyService.getCompanyByOwner(r.data.currentHashedRefreshToken)
              .then((r) => {
                if (r.status === 200) {
                  navigate("/dashboard");
                  sessionStorage.setItem("companyId", r.data.id);
                }
              })
              .catch((err) => {
                navigate("/create-company");
              });

            setMessage("Login Success");
          } else {
            setMessage("Vous n'avez pas les droits");
          }
        } else {
          setMessage("Login or Password not found");
        }
      })
      .catch((r) => {
        setMessage("Login or Password not found");
      });*/
  });



  const handleCloseSnackbar = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };

  return (
    <div className="view-login">
      <img className="app-logo" src="/assets/logo/logo.png" alt="" />
      <div className="body">
        <div className="left-part">
          <div className="title">{lang === "FR" ? "Bonjour, Bienvenue" : "Hi, Welcome back"}</div>
          <img className="login-img" src="/assets/images/login.png" alt="" />
        </div>
        <div className="right-part">
          <div className="title">{lang === "FR" ? "Se connecter au dashboard" : "Sign In to Dashboard "}</div>
          <form onSubmit={onSubmit} className="container-form">
            <input
              className="input-form"
              {...register("login")}
              placeholder={lang === "FR" ? "Pseudo ou Email" : "Username or Email"}
              type="text"
            />
            {errors?.login && <p className="error-message">{errors.login.message}</p>}
            <input
              className="input-form"
              type="password"
              placeholder={lang === "FR" ? "Mot de passe" : "Password"}
              {...register("password")}
            />
            {errors?.password && <p className="error-message">{errors.password.message}</p>}
            {/*<div className="button-reset" onClick={() => redirectToForgotPassword()}>
              forgot password ?
            </div>*/}
            <button className="button-submit">Login</button>
          </form>
          <img className="login-img-2" src="/assets/images/login-2.svg" alt="" />
        </div>
      </div>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Login;
