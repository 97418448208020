import { combineReducers } from "redux";
import userReducer from "./API/user.reducer";
import langReducer from "./API/lang.reducer";
import companyReducer from "./API/company.reducer";
import mediaCompanyReducer from "./API/media.company.reducer";
import notificationReducer from "./API/notification.reducer";


export default combineReducers({
  userReducer,
  langReducer,
  companyReducer,
  mediaCompanyReducer,
  notificationReducer
});
