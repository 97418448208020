import { Resolver } from "react-hook-form";
import { Auth } from "../models/auth.model";

const resolver: Resolver<Auth> = async (values) => {
  if (!values.login) {
    return {
      errors: {
        login: {
          type: "required",
          message: "This field is required",
        },
      },
      values: {},
    };
  } else if (!values.password) {
    return {
      errors: {
        password: {
          type: "required",
          message: "This field is required",
        },
      },
      values: {},
    };
  }

  return {
    errors: {},
    values,
  };
};
export default resolver;
