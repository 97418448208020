import WidgetChartLinear from "../../components/widget_charts_linear/WidgetChartLinear";
import CircleChart from "../../components/circle-chart/CircleChart";
import WidgetChartBar from "../../components/WidgetChartBar/WidgetChartBar";
import { useSelector } from "react-redux";

const Analytics = () => {
 

  const lang = useSelector((state: any) => state.langReducer);

  /*
   const [token, setToken] = useState<any>("");
  const [company, setCompagny] = useState<any>("");
  const [reciveidCard, setRecievedCard] = useState<Array<ChartResponse>>([]);
  const [forwardCard, setForwardCard] = useState<Array<ChartResponse>>([]);
  const [viewCard, setViewCard] = useState<Array<ChartResponse>>([]);
  function extractYValues(chartData: ChartResponse[]): number[] {
    return chartData.map((data) => data.y);
  }

  function extractXValues(chartData: ChartResponse[]): string[] {
    return chartData.map((data) => data.x);
  }*/

  /*useEffect(() => {
    if (!isEmpty(AuthService.getUserConnected())) {
      setToken(AuthService.getUserConnected());
      if (!isEmpty(AuthService.getCompanyConnect())) {
        setCompagny(AuthService.getCompanyConnect());
        StatsService.getAllEmployeePersonalCardView(company, token)
          .then((r) => {
            if (r.status === 200) {
             // setViewCard(r.data);
            }
          })
          .catch(() => console.log(""));
        StatsService.getCompanyForwardCard(company, token)
          .then((r) => {
            if (r.status === 200) {
                // setForwardCard(r.data);
            }
          })
          .catch(() => console.log(""));
        StatsService.getCompanyReceivedCard(company, token)
          .then((r) => {
            if (r.status === 200) {
              setRecievedCard(r.data);
            }
          })
          .catch(() => console.log(""));
      }
    }
  }, [token, company]);*/

  return (
    <div className="view--analytics">
      <div className="container-analytics marginTop">
        <WidgetChartLinear
          title={lang === "FR" ? "Nombre Cartes reçue par date" : "Number of Cards Received by Date"}
          subheader={lang === "FR" ? "Cartes Reçue /Date" : "Cards Received / Date"}
          chartData={[50,200,10,600,800,90,50,500,900,1000]}
          chartLabels={["1/02/2023","2/02/2023","3/02/2023","4/02/2023","5/02/2023","6/02/2023","7/02/2023","8/02/2023","9/02/2023","10/02/2023"]}
          width={500}
          widthBox={"50%"}
        />
        <CircleChart
          title={lang === "FR" ? "Cartes distribuée / Cartes reçues" : "Cartes forwoard / Cartes Received"}
          chartData={[40, 60]}
          chartlabels={[lang === "FR" ? "Distribuée" : "forwoard", lang === "FR" ? "Reçues" : "Received"]}
          chartColors={["#FF0000", "#FFF000", "#E2E2E2", "#2C2C2C"]}
          subheader={""}
        />
      </div>
      <div className="container-analytics marginTop">
        <WidgetChartBar
          chartData={[50,20,10,60,80,90,5,50,90,100]}
          chartLabels={["1/02/2023","2/02/2023","3/02/2023","4/02/2023","5/02/2023","6/02/2023","7/02/2023","8/02/2023","9/02/2023","10/02/2023"]}
          widthBox={"100%"}
          width={1000}
          title={
            lang === "FR"
              ? "Nombre de Cartes social network partager par Date"
              : "Number of Social Media Cards Shared by Date"
          }
          subheader={lang === "FR" ? "Cartes Réseaux Social/Date" : "Social Media Cards Shared / Date"}
        />
      </div>
    </div>
  );
};

export default Analytics;
